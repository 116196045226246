import { t } from "@/i18n-js/instance";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { FieldLabel } from "@circle-react/components/shared/Form/FieldLabel";
import { SpaceSelect } from "@circle-react/components/shared/SpaceSelect";
import { usePunditUserContext, useSpacesContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import {
  isDisplayCardView,
  isPublishedPost,
} from "@circle-react/helpers/postHelpers";
import { userIsAdminOrModeratorOfSpace } from "@circle-react/helpers/spaceHelpers";
import { usePlanFeatureFlags } from "@circle-react/hooks/usePlanFeatureFlags";
import { DateInput } from "@circle-react-uikit/DateInput";
import { Form } from "@circle-react-uikit/Form";
import { ImageInput } from "@circle-react-uikit/ImageInput";
import { Loader } from "@circle-react-uikit/Loader";

interface PlanFeatureFlags {
  internal_custom_html_enabled: boolean;
}

const hasInternalCustomHtmlEnabled = (flags: any): flags is PlanFeatureFlags =>
  flags?.internal_custom_html_enabled !== undefined;

interface SpacesHelpers {
  basicSpacesWithPostCreationAllowed: () => any;
}

const hasSpacesHelpers = (obj: any): obj is { helpers: SpacesHelpers } =>
  typeof obj?.helpers?.basicSpacesWithPostCreationAllowed === "function";

interface Space {
  current_user_roles: any;
}

const hasCurrentUserRoles = (obj: any): obj is Space =>
  typeof obj?.current_user_roles === "object";

export const Config = ({ space }: any) => {
  const { post } = useCurrentPostHelpers();
  const canChangeAuthor = hasCurrentUserRoles(space)
    ? !!userIsAdminOrModeratorOfSpace(space)
    : false;
  const spacesContext = useSpacesContext();
  let spaces;
  if (hasSpacesHelpers(spacesContext)) {
    spaces = spacesContext.helpers.basicSpacesWithPostCreationAllowed();
  }
  const { currentCommunityMember } = usePunditUserContext();
  const createdAtMaxDate = new Date().toUTCString();
  const { data: planFeatureFlags = {}, isLoading: isPlanFeatureFlagsLoading } =
    usePlanFeatureFlags({
      enabled: isCommunityAdmin(currentCommunityMember),
    });

  const isCustomHtmlEnabled: boolean = hasInternalCustomHtmlEnabled(
    planFeatureFlags,
  )
    ? planFeatureFlags.internal_custom_html_enabled
    : false;

  const isEditingPublishedPost = Boolean(post.id) && isPublishedPost(post);

  if (isPlanFeatureFlagsLoading) {
    return <Loader />;
  }
  return (
    <>
      {isEditingPublishedPost && (
        <div className="form-group form-group--column !mt-0">
          <FieldLabel
            field="created_at"
            label={t("publish_date")}
            description={t("publish_date_description")}
          />
          <DateInput name="created_at" maxDate={createdAtMaxDate} />
        </div>
      )}
      {isDisplayCardView(space) && (
        <div className="form-group form-group--two-columns">
          <FieldLabel
            field="cardview_thumbnail"
            label={t("cardview_thumbnail")}
          />
          <ImageInput
            name="cardview_thumbnail"
            aspectRatio={600 / 300}
            title={t("upload_a_cardview_thumbnail")}
          />
        </div>
      )}
      {isCommunityAdmin(currentCommunityMember) && isCustomHtmlEnabled && (
        <Form.Item
          hideBorder
          name="internal_custom_html"
          fullWidth
          translationRoot="post"
          hidePlaceholder
          variant="extra-bottom-margin"
        >
          <Form.TextArea name="internal_custom_html" />
        </Form.Item>
      )}
      <Form.Item
        variant="extra-bottom-margin"
        hideBorder
        name="space_id"
        translationRoot="post"
        fullWidth
      >
        <SpaceSelect name="space_id" variant="form-input" spaces={spaces} />
      </Form.Item>

      <Form.Item
        variant="extra-bottom-margin"
        hideBorder
        name="slug"
        fullWidth
        translationRoot="post"
        hidePlaceholder
      >
        <Form.Input name="slug" />
      </Form.Item>
      {canChangeAuthor && (
        <Form.Item
          variant="extra-bottom-margin"
          hideBorder
          name="user_id"
          translationRoot="post"
          fullWidth
        >
          <Form.CommunityMemberSelect
            name="user_id"
            direction="up"
            valueField="user_id"
          />
        </Form.Item>
      )}
    </>
  );
};
