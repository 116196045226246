import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { postApi } from "@circle-react/api";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";
import { usePostListPinnedOnSidebar } from "@circle-react/hooks/posts/usePostList";
import { Dropdown } from "@circle-react-uikit/Dropdown";

/**
 * @deprecated
 * Please use the new PinToSidebarAction component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PinToSidebarAction = ({ post = {}, handleClose, onChange }) => {
  const isPinned = !!post.pinned_at;
  const queryClient = useQueryClient();

  const { success, error } = useToast();
  const { refetch: refetchSideBarPosts } = usePostListPinnedOnSidebar();

  const handleOnClick = useMutation(
    () =>
      postApi.pin({
        post,
        formData: { ...post, is_pinned: !isPinned, pin_to_top: false },
      }),
    {
      onSuccess: post => {
        onChange(post, { refetch: false });
        refetchSideBarPosts();
        queryClient.invalidateQueries("posts");

        success(
          I18n.t(
            isPinned ? "post_unpinned_from_sidebar" : "post_pinned_to_sidebar",
          ),
        );
      },
      onError: () => {
        error(I18n.t(isPinned ? "post_unpinned_error" : "post_pinned_error"));
      },
      onSettled: handleClose,
    },
  );

  return (
    <Dropdown.ItemLink onClick={handleOnClick.mutate}>
      <ToggleSwitchWithLabel
        label={t("pin_post")}
        field="pin_post"
        value={isPinned}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};

PinToSidebarAction.propTypes = {
  post: PropTypes.object,
  handleClose: PropTypes.func,
  onChange: PropTypes.func,
};
