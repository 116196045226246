// TODO: remove this hook when we migrate to v2
import { useHistory, useLocation } from "react-router-dom";
import { getAdminAudienceEditPathsWithState } from "@circle-react/helpers/urlHelpers";
import type { AudienceType } from "@circle-react/types/AudienceList";

export const useAdminAudienceEditModal = () => {
  const location = useLocation();
  const history = useHistory();

  return ({
    publicId,
    type = "CommunityMember",
  }: {
    publicId: string;
    type?: AudienceType;
  }) => {
    history.push(
      getAdminAudienceEditPathsWithState({
        publicId,
        previousLocation: location,
        type,
      }).index,
    );
  };
};
