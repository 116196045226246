import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Input } from "@/react/components/FormV2/Input";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { ToggleSwitchWithLabel } from "@circle-react-shared/uikit/ToggleSwitchWithLabel";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { usePostShareOptions } from "./usePostShareOptions";

/**
 * @deprecated
 * Please use the new PostShareDropdown component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostShareDropdown = ({
  getSharePostLink,
  post,
  sharePostData,
}) => {
  const {
    onClickCopyLink,
    onChange,
    formData,
    currentCommunity,
    currentSpace,
  } = usePostShareOptions({
    post,
    getSharePostLink,
    sharePostData,
  });

  const { isViewOnlyMasquerading } = usePunditUserContext();

  const renderPublicCommunityPublicSpaceContent = () => (
    <div className="flex flex-row items-center px-4 py-2">
      <div className="flex flex-1 flex-col">
        <Typography.LabelMd weight="bold">
          {t("post.share.share_to_web")}
        </Typography.LabelMd>
        <Typography.LabelSm>
          {t("post.share.post_is_public")}
        </Typography.LabelSm>
      </div>
      <div>
        <Button variant="secondary" onClick={() => onClickCopyLink()}>
          {t("copy_link")}
        </Button>
      </div>
    </div>
  );

  const renderPublicCommunityPrivateSpaceContent = () => (
    <div>
      <Dropdown.ItemLink
        disabled={isViewOnlyMasquerading}
        onClick={() => {
          onChange({
            share_to_web: !formData.post.share_to_web,
          });
        }}
      >
        <ToggleSwitchWithLabel
          label={t("post.share.share_to_web")}
          tooltipText={t("post.share.share_to_web_on_description")}
          field="share_to_web"
          value={formData.post.share_to_web}
          variant="small"
          disabled={isViewOnlyMasquerading}
          className={classNames({
            "cursor-not-allowed": isViewOnlyMasquerading,
          })}
        />
      </Dropdown.ItemLink>
      {formData.post.share_to_web && (
        <>
          <div className="flex flex-row items-center">
            <div className="mb-4 mt-1 flex-1 px-5">
              <Input
                disabled
                value={sharePostData?.share_post_link}
                rightAddon={
                  <button
                    type="submit"
                    onClick={() =>
                      onClickCopyLink(sharePostData?.share_post_link)
                    }
                  >
                    <Icon type="20-copy" />
                  </button>
                }
              />
            </div>
          </div>
          <Dropdown.ItemLink
            onClick={() => {
              onChange({
                show_post_teaser: !formData.post.show_post_teaser,
              });
            }}
          >
            <ToggleSwitchWithLabel
              label={t("post.share.show_teaser")}
              tooltipText={
                <>
                  <p>{t("post.share.show_teaser_description_title")}</p>
                  <a href="/settings#field-custom_cta_for_share_links">
                    {t("post.share.show_teaser_description_link")}
                  </a>
                </>
              }
              field="show_post_teaser"
              value={formData.post.show_post_teaser}
              variant="small"
            />
          </Dropdown.ItemLink>
          {!currentCommunity?.is_private && (
            <Dropdown.ItemLink
              onClick={() =>
                onChange({
                  minimal_layout: !formData.post.minimal_layout,
                })
              }
            >
              <ToggleSwitchWithLabel
                label={t("post.share.minimal_layout")}
                tooltipText={t("post.share.minimal_layout_description")}
                field="minimal_layout"
                value={formData.post.minimal_layout}
                variant="small"
              />
            </Dropdown.ItemLink>
          )}
        </>
      )}
    </div>
  );

  const renderContent = () => {
    if (!currentCommunity.is_private && !currentSpace?.is_private) {
      return renderPublicCommunityPublicSpaceContent();
    }

    return renderPublicCommunityPrivateSpaceContent();
  };

  return renderContent();
};
