import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

interface Avatar {
  id: number;
  name: string;
  avatar_url: string;
}

export interface ParticipantsAvatarBlockProps {
  avatars: Avatar[];
}

export const ParticipantsAvatarBlock = ({
  avatars = [],
}: ParticipantsAvatarBlockProps) => (
  <div
    className={classNames(
      "bg-primary isolate flex items-center space-x-1 rounded-lg md:justify-center",
    )}
  >
    {avatars.map(({ id, name, avatar_url }) => (
      <UserImage key={id} size="6" src={avatar_url} name={name} />
    ))}
  </div>
);
