import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { LOCATION_TYPES } from "@circle-react/components/Events/helpers/constants";
import { AdvancedOptions } from "@circle-react/components/EventsV3/Modal/Tabs/AdvancedOptions";
import { BasicInfo } from "@circle-react/components/EventsV3/Modal/Tabs/BasicInfo";
import { Details } from "@circle-react/components/EventsV3/Modal/Tabs/Details";
import { Email } from "@circle-react/components/EventsV3/Modal/Tabs/Email";
import { Notifications } from "@circle-react/components/EventsV3/Modal/Tabs/Notifications";
import { Overview } from "@circle-react/components/EventsV3/Modal/Tabs/Overview";
import { People } from "@circle-react/components/EventsV3/Modal/Tabs/People";
import { useAddonsContext } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";
import { LimitsReached } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/Sections/Details/LimitsReached";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { SECTIONS } from "../../utils";

export const EventsBody = ({
  event,
  triggerValidationRef,
  setValueRef,
  setIsDirty,
  setLocationType,
  locationType,
  isLiveLocation,
  setStartsAtDirty,
  setPaywallAttributesDirty,
  isRefetchingAttendees,
}) => {
  const { currentSection } = useCustomizeUIData();
  const {
    trigger,
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  useEffect(() => {
    if (triggerValidationRef && trigger) {
      triggerValidationRef.current = trigger;
    }
  }, [triggerValidationRef, trigger]);

  useEffect(() => {
    if (setValueRef && setValue) {
      setValueRef.current = setValue;
    }
  }, [setValueRef, setValue]);

  // Note we need to generate a new array every time in order for useEffect to execute
  // otherwise the dirtyFields value (reference) won't change and this won't work
  const dirtyValues = Object.values(dirtyFields);
  const startsAtChanged = !!dirtyFields?.event_setting_attributes?.starts_at;
  const didPaywallAttributesChanged =
    !!dirtyFields?.paywall_attributes?.price_amount ||
    !!dirtyFields?.paywall_attributes?.description;
  useEffect(() => {
    const isDirty = dirtyValues?.some(value => !!value);
    setIsDirty(isDirty);
  }, [setIsDirty, dirtyValues]);

  useEffect(() => {
    setStartsAtDirty(startsAtChanged);
  }, [setStartsAtDirty, startsAtChanged]);

  useEffect(() => {
    setPaywallAttributesDirty(didPaywallAttributesChanged);
  }, [setPaywallAttributesDirty, didPaywallAttributesChanged]);

  const { limitsReachedIsOpen } = useAddonsContext();

  const roomType = () => {
    switch (locationType) {
      case LOCATION_TYPES.LIVE_STREAM:
        return ROOM_TYPES.STREAM;
      case LOCATION_TYPES.LIVE_ROOM:
        return ROOM_TYPES.CONFERENCE;
    }
  };

  return (
    <>
      {isLiveLocation && limitsReachedIsOpen && (
        <LimitsReached roomType={roomType()} />
      )}
      <div className="p-4 md:p-6 lg:py-8">
        {currentSection === SECTIONS.overview && <Overview event={event} />}
        {currentSection === SECTIONS.people && (
          <People event={event} isRefetchingAttendees={isRefetchingAttendees} />
        )}
        {currentSection === SECTIONS.basicInfo && (
          <BasicInfo event={event} setLocationType={setLocationType} />
        )}
        {currentSection === SECTIONS.details && <Details />}
        {currentSection === SECTIONS.notifications && (
          <Notifications event={event} />
        )}
        {currentSection === SECTIONS.email && <Email event={event} />}
        {currentSection === SECTIONS.advancedOptions && (
          <AdvancedOptions event={event} />
        )}
      </div>
    </>
  );
};

EventsBody.propTypes = {
  event: PropTypes.object,
  setValueRef: PropTypes.any,
  triggerValidationRef: PropTypes.shape({
    current: PropTypes.func,
  }),
  setIsDirty: PropTypes.func.isRequired,
  setLocationType: PropTypes.func,
  locationType: PropTypes.string,
  isLiveLocation: PropTypes.bool,
  setStartsAtDirty: PropTypes.func.isRequired,
  setPaywallAttributesDirty: PropTypes.func.isRequired,
};
