import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { notifyBugsnag } from "@circle-react/helpers/bugsnagHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { memberProfileModalPathsWithState } from "@circle-react/helpers/urlHelpers";
import type { AudienceType } from "@circle-react/types/AudienceList";
import { useAdminAudienceEditModal } from "../useAdminAudienceEditModal";
import { useNavigateToAudienceEdit } from "../useNavigateToAudienceEdit";
import { useProfileFieldsForLeadsEnabled } from "../useProfileFieldsForLeadsEnabled";

interface Member {
  sqid?: string;
  id: number;
  public_uid?: string;
  type?: AudienceType;
}

interface ShowMemberProfileEditParams {
  member?: Member;
  memberPublicId?: string;
}

const getPublicId = ({
  member,
  memberPublicId,
}: ShowMemberProfileEditParams): string => {
  if (memberPublicId) return memberPublicId;
  return member!.public_uid!;
};

export const useShowProfileEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const showAdminAudienceEditModal = useAdminAudienceEditModal();
  const navigateToAudienceEdit = useNavigateToAudienceEdit();
  const isProfileFieldsForLeadsEnabled = useProfileFieldsForLeadsEnabled();

  const showOwnProfileEdit = useCallback(() => {
    history.push(memberProfileModalPathsWithState.profile(location));
  }, [history, location]);

  const showMemberProfileEdit = useCallback(
    ({ member, memberPublicId }: ShowMemberProfileEditParams) => {
      if (!isAdmin) {
        console.assert(false, {
          message: t("show_member_profile_no_admin"),
          currentCommunityMember,
        });
        return;
      }

      if (isProfileFieldsForLeadsEnabled && member) {
        if (member.sqid) {
          return navigateToAudienceEdit({ sqid: member.sqid });
        }

        const errorMessage = `Missing SQID for audience edit navigation ${member.public_uid} ${member.id} falling back to old navigation`;
        console.error(errorMessage);
        notifyBugsnag(errorMessage);
      }

      invariant(
        memberPublicId || member?.public_uid,
        "member or memberPublicId is required",
      );

      const publicId = getPublicId({ member, memberPublicId });
      const audienceType = member?.type ?? "CommunityMember";

      return showAdminAudienceEditModal({ publicId, type: audienceType });
    },
    [
      currentCommunityMember,
      isAdmin,
      isProfileFieldsForLeadsEnabled,
      navigateToAudienceEdit,
      showAdminAudienceEditModal,
    ],
  );

  return {
    showOwnProfileEdit,
    showMemberProfileEdit,
  };
};
