import type { Comment } from "@/react/types/Comment";
import { DisplayTiptapContent } from "@circle-react/components/CommentsV2/Comment/DisplayTiptapContent";
import { isSafari } from "@circle-react/helpers/browserHelpers";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import { SeeMoreLess } from "@circle-react-uikit/SeeMoreLess";

interface BodyProps {
  comment: Comment;
}

/**
 * @deprecated
 * Please use the new Body component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const Body = ({ comment }: BodyProps) => {
  const { body_trix_content, editor, tiptap_body, updated_at } = comment;
  const isTiptap = editor === "tiptap";

  return (
    <div className="post__body" data-testid="comment-body">
      <div
        key={updated_at}
        className={classNames("post__inside text-dark !line-clamp-none", {
          "!overflow-auto": isSafari(),
        })}
        data-testid="comment-body-inside"
      >
        <SeeMoreLess lines={8}>
          {isTiptap ? (
            <DisplayTiptapContent tiptapBody={tiptap_body} />
          ) : (
            <DisplayTrixContent content={body_trix_content} />
          )}
        </SeeMoreLess>
      </div>
    </div>
  );
};
