import { useEffect, useState } from "react";
import classNames from "classnames";
import { isString } from "lodash";
import { t } from "@/i18n-js/instance";
import { ProfileAbout } from "@circle-react/components/ProfileModalV3/Body/About";
import { ProfileComments } from "@circle-react/components/ProfileModalV3/Body/Comments";
import { ProfilePosts } from "@circle-react/components/ProfileModalV3/Body/Posts";
import { ProfileHeader } from "@circle-react/components/ProfileModalV3/Body/ProfileHeader";
import { ProfileRewards } from "@circle-react/components/ProfileModalV3/Body/Rewards";
import { ProfileSpaces } from "@circle-react/components/ProfileModalV3/Body/Spaces";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useSmOrMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { TabItem } from "./TabItem";

const PROFILE_TABS = [
  "posts",
  "comments",
  "spaces",
  "about",
  "rewards",
] as const;
type Tab = (typeof PROFILE_TABS)[number];

const isValidTab = (tab: string): tab is Tab =>
  PROFILE_TABS.findIndex(t => t === tab) !== -1;

export const PROFILE_MODAL_CONTAINER_ID = "profile-modal-scrollable-target";

export const Body = () => {
  const isSmallScreen = useSmOrMdScreenMediaQuery();
  const { member, isFullPageView } = useProfileContext();
  const { currentCommunityMember } = usePunditUserContext();
  const [activeTab, setActiveTab] = useState<Tab>(
    isSmallScreen ? "about" : "posts",
  );

  const { data: gamificationSettings } = useGamificationSettings();

  const isMyProfile = currentCommunityMember?.id === member.id;
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  const shouldShowRewardsTab =
    isMyProfile ||
    gamificationSettings?.is_member_rewards_tab_public ||
    isAdmin;

  const ComponentMap: Record<Tab, React.ComponentType> = {
    posts: ProfilePosts,
    comments: ProfileComments,
    spaces: ProfileSpaces,
    about: ProfileAbout,
    rewards: shouldShowRewardsTab ? ProfileRewards : () => null,
  };

  const ComponentToRender = ComponentMap[activeTab];
  const { tab: defaultTabFromQuery } = useRouterQueryParams();

  useEffect(() => {
    if (isString(defaultTabFromQuery) && isValidTab(defaultTabFromQuery)) {
      setActiveTab(defaultTabFromQuery);
    }
  }, [defaultTabFromQuery]);

  return (
    <div
      className={classNames("bg-primary flex flex-col overflow-auto", {
        "border-primary w-full rounded-xl border p-4 md:w-2/3 md:p-8":
          isFullPageView,
        "col-span-12 md:col-span-9": !isFullPageView,
      })}
    >
      <ProfileHeader />
      <div
        className="my-6 flex items-center space-x-2 overflow-x-auto"
        data-testid="tab-list"
      >
        {isSmallScreen && (
          <TabItem
            onClick={() => setActiveTab("about")}
            title={t("profile_drawer.about")}
            isActive={activeTab === "about"}
          />
        )}
        <TabItem
          onClick={() => setActiveTab("posts")}
          title={t("profile_drawer.posts.title")}
          isActive={activeTab === "posts"}
          count={member.posts_count}
        />
        <TabItem
          onClick={() => setActiveTab("comments")}
          title={t("profile_drawer.comments.title")}
          isActive={activeTab === "comments"}
          count={member.comments_count}
        />
        <TabItem
          onClick={() => setActiveTab("spaces")}
          title={t("profile_drawer.spaces.title")}
          isActive={activeTab === "spaces"}
          count={member.spaces_count}
        />
        {shouldShowRewardsTab && (
          <TabItem
            onClick={() => setActiveTab("rewards")}
            title={t("profile_drawer.rewards.title")}
            isActive={activeTab === "rewards"}
          />
        )}
      </div>
      <div
        id={PROFILE_MODAL_CONTAINER_ID}
        className="flex-1 overflow-auto pb-6"
        data-testid="profile-modal-tab-content"
      >
        <ComponentToRender />
      </div>
    </div>
  );
};
