import { t } from "@/i18n-js/instance";
import { isBasicPost } from "@circle-react/helpers/postHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PinnedToTopIcon = ({ post }) => {
  const isSmScreen = useSmScreenMediaQuery();
  if (!post.pinned_at_top_of_space) {
    return null;
  }

  if (isSmScreen && isBasicPost(post)) {
    return (
      <div className="flex items-center gap-2">
        <Icon size={20} type="20-pinned-post" className="text-dark" />
        <Typography.LabelXxs weight="font-semibold">
          {t("post.pinned_post")}
        </Typography.LabelXxs>
      </div>
    );
  }

  return (
    <div className="post__pin post__pin--pinned">
      <Icon type="pin" />
    </div>
  );
};
