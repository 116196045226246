import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { fetchRequest } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { Post } from "@circle-react/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";

const REPORT_REASONS = [
  "harassment",
  "spam",
  "incorrect_location",
  "against_guidelines",
  "other",
];

export interface ReportPostModalProps {
  onClose: () => void;
  show: boolean;
  post: Post;
}

export const ReportPostModal = ({
  onClose,
  show,
  post,
}: ReportPostModalProps) => {
  const toast = useToast();

  const reportPost = (data: any) =>
    fetchRequest(internalApi.flaggedContent.create(), {
      method: "POST",
      forReactQuery: true,
      body: JSON.stringify({ flagged_content: data }),
    });

  const handleReport = useMutation(reportPost, {
    onSuccess: () => {
      toast.success(t("report_post_success"));
      onClose();
    },
    onError: () => {
      toast.error(t("something_went_wrong"));
    },
  });

  const reportReasonOptions = REPORT_REASONS.map(reason => ({
    value: reason,
    label: t(`flagged_contents.report_reasons.${reason}`),
  }));

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      title={t("flagged_contents.form.report_post")}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Form
          defaultValues={{
            flagged_contentable_id: post.id,
            flagged_contentable_type: "Post",
          }}
          onSubmit={(data: any) => handleReport.mutate(data)}
        >
          <Modal.Header>
            <Modal.Title size="sm">
              {t("comments.report.form.title")}
            </Modal.Title>
            <Modal.HeaderActions>
              <Modal.CloseButton onClick={onClose} />
            </Modal.HeaderActions>
          </Modal.Header>
          <Modal.Body>
            <Form.Item
              name="reported_reason_type"
              label={t("flagged_contents.form.reported_reason_type")}
              description={t(
                "flagged_contents.form.reported_reason_type_description",
              )}
              hideBorder
            >
              <Form.SelectNative
                name="reported_reason_type"
                options={reportReasonOptions}
              />
            </Form.Item>
            <Form.Item
              name="reported_reason_body"
              label={t("flagged_contents.form.reported_reason_body")}
              description={t(
                "flagged_contents.form.reported_reason_body_description",
              )}
              hideBorder
              fullWidth
            >
              <Form.TextArea name="reported_reason_body" />
            </Form.Item>
          </Modal.Body>
          <Modal.Footer className="flex justify-end">
            <Button
              type="button"
              variant="secondary"
              onClick={onClose}
              className="mr-2"
              disabled={handleReport.isLoading}
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              variant="circle"
              disabled={handleReport.isLoading}
              data-testid="report_post_submit"
            >
              {t("report")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
