import copyToClipboard from "copy-to-clipboard";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { communityMemberProfilePath } from "@circle-react/helpers/urlHelpers";
import { useShowProfileEdit } from "@circle-react/hooks/profile/useShowProfileEdit";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useMemberProfileActions = ({
  member,
  memberPublicId,
  isFullPageProfile = false,
  shouldHideMessageButton = false,
}: {
  member: any;
  memberPublicId: string;
  isFullPageProfile?: boolean;
  shouldHideMessageButton?: boolean;
}) => {
  const { success } = useToast();
  const profilePath: string = communityMemberProfilePath({
    public_uid: memberPublicId,
  });
  const { showOwnProfileEdit, showMemberProfileEdit } = useShowProfileEdit();
  const { currentCommunityMember } = usePunditUserContext();

  const isCurrentCommunityMemberProfile =
    currentCommunityMember?.id == member?.id;
  const isAdminUser = currentCommunityMember?.is_admin;

  const shouldShowEditProfileButton =
    !isFullPageProfile &&
    !member?.is_deleted &&
    (isCurrentCommunityMemberProfile || isAdminUser);

  const shouldShowMemberChatIcon =
    !shouldHideMessageButton &&
    !member?.is_deleted &&
    !isCurrentCommunityMemberProfile &&
    currentCommunityMember?.messaging_enabled &&
    (member.messaging_enabled || (isAdminUser && !member.admin));

  const canEditProfile = !member?.is_deleted && isAdminUser;
  const canViewOnlyMasquerade =
    isAdminUser && currentCommunityMember?.id !== member.id;

  const onClickOpenEditProfileDrawer = () => {
    if (isAdminUser) {
      showMemberProfileEdit({ member });
    } else if (isCurrentCommunityMemberProfile) {
      showOwnProfileEdit();
    }
  };

  const handleCopyProfileLink = () => {
    copyToClipboard(window.location.origin + profilePath);
    success(t("post.share.link_copied"), {
      duration: "short",
      shouldUseProgress: false,
    });
  };

  return {
    shouldShowEditProfileButton,
    onClickOpenEditProfileDrawer,
    handleCopyProfileLink,
    shouldShowMemberChatIcon,
    canEditProfile,
    canViewOnlyMasquerade,
  };
};
