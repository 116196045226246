import { useState } from "react";
import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { t } from "@/i18n-js/instance";
import {
  getActiveMessageId,
  getDefaultMessagePortalId,
} from "@circle-react/components/Chat/ChatWindowV2/helpers";
import { Icon } from "@circle-react/components/shared/Icon";
import { useScrollInfo } from "@circle-react/hooks/chatsV2/useScrollInfo";
import { TippyV2 as Tippy } from "@circle-react-shared/TippyV2";
import type { ReactionType } from "../Reaction";
import { PopoverContent } from "./PopoverContent";

export interface PopoverWrapperProps {
  reactions: ReactionType[];
  currentCommunityMemberId: number;
  reactionableId: number;
  reactionableType: string;
  showBorder?: boolean;
  setActiveMessageId?: (messageId: string) => void;
  isMessageThread?: boolean;
  portalTargetId?: string | null;
}

export const PopoverWrapper = ({
  reactions,
  currentCommunityMemberId,
  reactionableId,
  reactionableType,
  showBorder = false,
  setActiveMessageId,
  isMessageThread = false,
}: PopoverWrapperProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: showBorder ? "top-start" : "bottom-end",
  });

  const { scrollerId } = useScrollInfo();

  const portalRoot =
    (scrollerId && document.getElementById(scrollerId)) ||
    getDefaultMessagePortalId();

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={classNames(
              "hover:bg-secondary focus:bg-secondary flex items-center justify-center rounded transition-transform",
              {
                "border-primary rounded-full border px-1 hover:border-gray-400":
                  showBorder,
                "h-6 w-6": !showBorder,
              },
            )}
            onClick={() =>
              !open &&
              setActiveMessageId &&
              setActiveMessageId(
                getActiveMessageId(reactionableId, isMessageThread),
              )
            }
          >
            <Tippy
              placement="bottom"
              interactive={false}
              content={t("tiptap.add_reaction")}
              dataTestId="add_reaction"
              className="flex"
            >
              <Icon
                aria-label={t("tiptap.add_reaction")}
                type="16-reaction"
                viewBox="-2 -2 20 20"
                className="text-default"
              />
            </Tippy>
          </Popover.Button>
          {portalRoot &&
            createPortal(
              <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                className="z-10"
                data-testid="filter_component"
                {...attributes.popper}
              >
                <div className="border-primary mt-1 rounded-lg border shadow-xl">
                  <PopoverContent
                    reactions={reactions}
                    reactionableId={reactionableId}
                    reactionableType={reactionableType}
                    onClose={close}
                    currentCommunityMemberId={currentCommunityMemberId}
                  />
                </div>
              </Popover.Panel>,
              portalRoot,
            )}
        </>
      )}
    </Popover>
  );
};
