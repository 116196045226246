import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { useChatsV2Api } from "@circle-react/hooks/chatsV2/useChatsV2Api";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { getDeleteConfirmationMessageText } from "../helpers";
import { MessageBlock } from "./MessageBlock";

export interface DeleteMessageItemModalProps {
  chatRoom: any;
  currentParticipant?: any;
  handleSubmit: any;
  isEditMessage?: boolean;
  isInitialPage?: boolean;
  message: any;
  messageId: number;
  onConfirm?: () => void;
  participants?: any;
  repliesCount: number;
}

const DeleteMessageItemModalComponent = ({
  chatRoom,
  currentParticipant,
  handleSubmit,
  messageId,
  isEditMessage = false,
  isInitialPage = false,
  message,
  onConfirm = noop,
  participants,
  repliesCount,
}: DeleteMessageItemModalProps) => {
  const modal = useModal();
  const { deleteRichTextMessage }: any = useChatsV2Api();
  return (
    <ConfirmationModal
      isOpen={modal.visible}
      onClose={modal.visible ? modal.hide : noop}
      onConfirm={() => {
        deleteRichTextMessage({
          chatRoomUuid: chatRoom.uuid,
          messageId,
        });
        void modal.hide();
        onConfirm();
      }}
      confirmText={t("delete")}
      confirmVariant="danger"
      title={t("messaging.delete_message.title")}
    >
      <div className="mb-6">
        {getDeleteConfirmationMessageText(repliesCount)}
      </div>
      <div className="border-primary w-full rounded-md border py-5">
        <MessageBlock
          isModal
          handleSubmit={handleSubmit}
          currentParticipant={currentParticipant}
          message={message}
          isInitialPage={isInitialPage}
          isEditMessage={isEditMessage}
          chatRoom={chatRoom}
          participants={participants}
        />
      </div>
    </ConfirmationModal>
  );
};

const DeleteMessageItemModal = ModalManager.create(
  (props: DeleteMessageItemModalProps) => (
    <DeleteMessageItemModalComponent {...props} />
  ),
);

export const useDeleteMessageItemModal = () => useModal(DeleteMessageItemModal);
