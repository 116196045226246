import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";
import { reactQueryPatch } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Dropdown } from "@circle-react-uikit/Dropdown";

/**
 * @deprecated
 * Please use the new DisableCommentsAction component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const DisableCommentsAction = ({ post, handleClose, onChange }) => {
  const { is_comments_disabled: isCommentsDisabled } = post;
  const translationRoot = isCommentsDisabled
    ? "post.enable_comments"
    : "post.disable_comments";
  const { success, error } = useToast();

  const { mutate: toggleCommentsDisabled } = useMutation(
    () =>
      reactQueryPatch(
        internalApi.posts.update({
          spaceId: post.space_id,
          postSlug: post.slug,
        }),
        {
          post: {
            space_id: post.space_id,
            is_comments_disabled: !isCommentsDisabled,
          },
        },
      ),
    {
      onSuccess: postData => {
        onChange(postData, { refetch: false });

        success(t(`${translationRoot}.alerts.success`));
      },
      onError: () => {
        error(t(`${translationRoot}.alerts.failure`));
      },
      onSettled: handleClose,
    },
  );

  return (
    <Dropdown.ItemLink onClick={toggleCommentsDisabled}>
      <ToggleSwitchWithLabel
        label={t("post.action_menu.disable_comments")}
        field="disable_comments"
        value={isCommentsDisabled}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};

DisableCommentsAction.propTypes = {
  post: PropTypes.shape({
    space_id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    is_comments_disabled: PropTypes.bool.isRequired,
  }).isRequired,
  handleClose: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};
