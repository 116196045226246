import { t } from "@/i18n-js/instance";
import { LevelBadge } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/LevelBadge";
import { usePointsInfoModal } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/PointsInfoModal";
import { useGamificationMember } from "@circle-react/components/Profile/HoverCard/useGamificationMember";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const GamificationInfo = () => {
  const { isV3Enabled } = useIsV3();
  const { data: gamificationSettings } = useGamificationSettings();
  const pointsInfoModal = usePointsInfoModal();
  const { member, isLoading } = useProfileContext();
  const { data: gamificationMembers } = useGamificationMember({
    enabled: !isLoading,
    member_ids: [member?.id],
  });
  const gamificationMember = gamificationMembers?.[0];

  if (!gamificationSettings?.enabled || !isV3Enabled) return null;

  return (
    <div className="border-primary mb-0 block border-b pb-4 md:m-6 md:pb-6">
      {isLoading || !gamificationMember ? (
        <div className="bg-tertiary h-6 w-32 animate-pulse rounded-full text-center text-transparent md:text-left" />
      ) : (
        <LevelBadge
          level={gamificationMember?.current_level}
          levelName={gamificationMember?.current_level_name}
        />
      )}
      <br />
      {gamificationMember && (
        <Button
          type="button"
          variant="text"
          className="md:flex"
          onClick={() => pointsInfoModal.show()}
        >
          <Typography.LabelXs weight="regular">
            <div className="text-light">
              {gamificationMember.points_to_next_level === 0
                ? t("leaderboard.maxed_out")
                : t("leaderboard.n_points_to_level_up", {
                    count: gamificationMember?.points_to_next_level,
                  })}
              <Icon type="question-mark" className="text-light ml-1 !w-3" />
            </div>
          </Typography.LabelXs>
        </Button>
      )}
    </div>
  );
};
