import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@circle-react/helpers/communityMemberHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";

export interface SelectedTopicProps {
  topic: { id: number; name: string; admin_only: boolean };
  isDragOverlay?: boolean;
  isDraggable?: boolean;
  setActiveTopicIDs?: (updateFunction: (prev: number[]) => number[]) => void;
}

export const SelectedTopic = ({
  topic,
  isDragOverlay,
  isDraggable,
  setActiveTopicIDs,
}: SelectedTopicProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdminOrModerator =
    isCommunityAdmin(currentCommunityMember) ||
    isCommunityModerator(currentCommunityMember);
  const isDisabledTopic = !isAdminOrModerator && topic.admin_only;
  return (
    <TippyV2
      placement="top"
      content={t("quick_post_v2.topics.admin_only")}
      interactive={false}
      disabled={!isDisabledTopic}
      trigger="mouseenter click focus focusin"
    >
      <div
        className={classNames(
          "border-primary bg-primary text-dark inline-flex items-center justify-start gap-1.5 whitespace-nowrap rounded-[32px] border py-1.5 pr-2",
          isDragOverlay
            ? "rotate-1 scale-[1.01] opacity-75 shadow-lg"
            : isDraggable
              ? "cursor-move"
              : "cursor-default",
          isDisabledTopic ? "pl-2" : "pl-3.5",
          {
            "pointer-events-none": isDisabledTopic,
          },
        )}
      >
        <Typography.LabelXs weight="medium">{topic?.name}</Typography.LabelXs>
        {(isAdminOrModerator || !topic.admin_only) && (
          <Icon
            className="cursor-pointer"
            onClick={() => {
              setActiveTopicIDs?.(prev =>
                prev.filter(prevTopic => prevTopic !== topic.id),
              );
            }}
            type="12-close"
            size={12}
          />
        )}
      </div>
    </TippyV2>
  );
};
