import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import {
  CommunityMemberPicker,
  VARIANTS,
} from "@circle-react-shared/CommunityMemberPicker";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../../Common";
import { useEventAttendeesApi } from "../../../hooks/useEventAttendeesApi";
import { ATTENDEE_ROLES } from "../../../utils";
import { AttendeesWarningMessage } from "../AttendeesWarningMessage";
import { List } from "../List";
import { Pagination } from "../Pagination";

export const Attendees = ({ event, isRefetchingAttendees }) => {
  const {
    records,
    refetch,
    fetchPrevPage,
    fetchNextPage,
    hasPrevPage,
    hasNextPage,
    totalRecordCount,
    totalConfirmedRecordCount,
    addAttendeesAsync,
    startRecordNumber,
    endRecordNumber,
  } = useEventAttendeesApi(event, ATTENDEE_ROLES.PARTICIPANT);
  const { autoRsvpInvitedAttendeesEnabled } = useEventsFeatureFlags();
  const { resetField } = useFormContext();
  const onAddAttendees = async entityIds => {
    if (
      !entityIds?.spaces_ids?.length &&
      !entityIds?.space_groups_ids?.length &&
      !entityIds?.member_tags_ids?.length &&
      !entityIds?.members_ids?.length
    ) {
      return;
    }
    try {
      await addAttendeesAsync({ invited_entities_ids: entityIds });
      resetField("attendee_invite_attributes.invited_entities_ids");
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="flex h-full flex-col items-stretch pt-4">
      <div className="flex w-full items-center justify-between">
        <Typography.LabelMd weight="semibold">
          {`${totalConfirmedRecordCount || 0} ${t(
            "events.edit_modal.people.attendees",
          )}`}
        </Typography.LabelMd>
        <FormItem
          name="attendee_invite_attributes.invited_entities_ids"
          className="!w-auto"
          hideLabel
        >
          <CommunityMemberPicker
            variant={VARIANTS.BUTTON}
            buttonLabel={t("events.edit_modal.people.add_attendees")}
            selectionLabel={t(
              "events.edit_modal.people.attendees_selection_label",
            )}
            searchPlaceholder={t(
              "events.edit_modal.people.attendees_placeholder",
            )}
            modalSaveButtonLabel={t("save")}
            onModalSave={onAddAttendees}
            renderAfterSelection={<AttendeesWarningMessage />}
            includeAllResultTypes
          />
        </FormItem>
      </div>
      {autoRsvpInvitedAttendeesEnabled && (
        <div className="mb-3 mt-2">
          <FormItem
            name="event_setting_attributes.auto_rsvp_invited_attendees"
            tooltipText={t(
              "events.edit_modal.event_setting_attributes.auto_rsvp_invited_attendees_tooltip",
            )}
            inlineReverse
            className="!pb-0"
            tooltipTextClassName="text-center"
          >
            <Form.ToggleSwitch
              name="event_setting_attributes.auto_rsvp_invited_attendees"
              variant="small"
            />
          </FormItem>
        </div>
      )}
      <div className="mt-4" />
      {records?.length > 0 && (
        <List
          event={event}
          records={records}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          onChange={refetch}
          isRefetchingAttendees={isRefetchingAttendees}
        />
      )}
      {totalRecordCount > 0 && (
        <Pagination
          startRecordNumber={startRecordNumber}
          endRecordNumber={endRecordNumber}
          totalRecordCount={totalRecordCount}
          fetchPrevPage={fetchPrevPage}
          fetchNextPage={fetchNextPage}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      )}
    </div>
  );
};

Attendees.propTypes = {
  event: PropTypes.object.isRequired,
};
