import { useState } from "react";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { supportWidgetApi } from "@circle-react/api/supportWidgetApi";
import { BodyMd } from "@circle-react-shared/uikit/Typography/components/Body/BodyMd";
import { LabelMd } from "@circle-react-shared/uikit/Typography/components/Label/LabelMd";
import { BooleanButton } from "../SupportWidget/BooleanButton";

/**
 * @deprecated
 * Please use the new KnowledgeBaseFeedbackSection component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const KnowledgeBaseFeedbackSection = ({ post }) => {
  const [feedbackPositive, setFeedbackPositive] = useState(null);
  const [isFeedBackGiven, setIsFeedbackGiven] = useState(false);
  const { mutate: createPostFeedback } = useMutation(({ postId, value }) =>
    supportWidgetApi.createPostFeedback({
      postId: postId,
      helpful: value,
    }),
  );

  const handleFeedback = value => {
    if (isFeedBackGiven) return;

    setFeedbackPositive(value);
    createPostFeedback({ postId: post.id, value });
    setIsFeedbackGiven(true);
  };

  return (
    <div className="mx-auto w-full pt-4">
      <div className="mb-4">
        <LabelMd weight="semibold">
          {t("support_widget.was_this_article_helpful")}
        </LabelMd>
      </div>
      <div className="mb-6 flex justify-center gap-4">
        <BooleanButton
          value={feedbackPositive}
          handleFeedback={handleFeedback}
          isFeedbackGiven={isFeedBackGiven}
        />
      </div>
      <div>
        {(feedbackPositive || feedbackPositive === false) && (
          <div className="mb-2">
            <BodyMd>
              {t("support_widget.thank_you_for_article_feedback")}
            </BodyMd>
          </div>
        )}
      </div>
    </div>
  );
};
