import { t } from "@/i18n-js/instance";
import { viewOnlyMasqueradeAs } from "@circle-react/api/masqueradingApi";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Button } from "@circle-react-uikit/Button";
import { Icon } from "../shared/Icon";

interface ViewOnlyMasqueradButtonProps {
  member: Member;
}

interface Member {
  id: string;
  first_name: string;
}

export const ViewOnlyMasqueradeButton = ({
  member,
}: ViewOnlyMasqueradButtonProps) => {
  const handleClick = () =>
    viewOnlyMasqueradeAs({
      entityId: member.id,
      entityType: "CommunityMember",
    })
      .then(data => window.open(data.masquerade_url, "_self"))
      .catch(error => console.error(error));

  return (
    <TippyV2
      placement="bottom"
      content={t("post.share.view_as", {
        member_first_name: member.first_name,
      })}
      interactive={false}
    >
      <Button
        variant="secondary"
        onClick={handleClick}
        aria-label={t("post.share.view_as", {
          member_first_name: member.first_name,
        })}
        data-testid="view-as-masquerade"
      >
        <Icon
          type="16-eye-open"
          size={16}
          useWithStrokeCurrentColor
          aria-hidden
        />
      </Button>
    </TippyV2>
  );
};
