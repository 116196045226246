/* eslint-disable react-hooks/rules-of-hooks
 -- Disabled to set CI to fail on this issue on new files, PR #5195 */
import classnames from "classnames";
import { truncate } from "lodash";
import { t } from "@/i18n-js/instance";
import { PostActionsDropdownMenu } from "@/react/components/PostsV3/PostViewListItem/PostActionsDropdownMenu";
import { ShowPageLink } from "@/react/components/PostsV3/PostViewListItem/ShowPageLink";
import { staticAssetUrl } from "@circle-react/config/Settings";
import { useCurrentPostContext } from "@circle-react/contexts";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useThemeContext } from "@circle-react/providers";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { CardViewItemLastRepliedOrPosted } from "./CardViewItemLastRepliedOrPosted";
import { EngagementActions } from "./EngagementActions";

export interface MainContentProps {
  onPostChange: (post: any, options?: any) => void;
  onPostDestroy: (post: any) => void;
  refetchPosts: () => void;
}

export const MainContent = ({
  onPostChange,
  onPostDestroy,
  refetchPosts,
}: MainContentProps) => {
  const { record: post, isUpdatingCardViewThumbnail } = useCurrentPostContext();
  const { isDarkAppearance } = useThemeContext();
  const { isV3Enabled } = useIsV3();

  if (!post) {
    return null;
  }

  const {
    flagged_for_approval_at,
    cardview_thumbnail_url,
    community_member,
    hide_meta_info,
    display_title,
    is_liking_enabled,
    is_comments_enabled,
    cover_image_url,
  } = post;

  const hasEngagementActions = is_liking_enabled || is_comments_enabled;

  const containerClassNames = classnames(
    "post--card post--parent border-primary bg-primary border relative overflow-hidden",
    {
      "flagged-for-approval": flagged_for_approval_at,
      "has-engagement pb-10": hasEngagementActions,
      "rounded-lg": !isV3Enabled,
      "rounded-2xl": isV3Enabled,
    },
  );

  const cardViewCoverImageUrl = cardview_thumbnail_url || cover_image_url;

  const getCardViewCoverImageStyle = () => {
    if (cardViewCoverImageUrl)
      return { backgroundImage: `url(${cardViewCoverImageUrl})` };

    const coverImageUrl = isDarkAppearance
      ? staticAssetUrl("dark-mode-cover-thumbnail-2x.png")
      : staticAssetUrl("light-mode-cover-thumbnail-2x.png");
    return { backgroundImage: `url(${coverImageUrl})` };
  };

  const onChange = (updates = {}, options = {}) => {
    onPostChange({ ...post, ...updates }, options);
  };

  const profileLinkProps = useProfileLinkProps({
    public_uid: community_member.public_uid,
    params: { show_back_link: true },
  });

  return (
    <div className={containerClassNames}>
      <div className="post--card__post text-dark">
        <ShowPageLink
          post={post}
          className="post--card__cover text-dark bg-primary focus-visible:outline-secondary focus-visible:text-dark relative block aspect-[2] rounded-t-lg bg-cover bg-center bg-no-repeat focus-visible:rounded-t-lg focus-visible:outline focus-visible:outline-2"
          style={getCardViewCoverImageStyle()}
          shouldOpenPostModal
        >
          {isUpdatingCardViewThumbnail && (
            <div className="bg-primary absolute inset-0 flex items-center justify-center rounded-t-lg">
              <Loader />
            </div>
          )}
        </ShowPageLink>
        {!hide_meta_info && (
          <div className="post--card__user has-cover -mt-5 px-5">
            <div className="post__avatar">
              <a
                className="user__avatar focus-visible:outline-secondary focus-visible:text-dark focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                {...profileLinkProps}
              >
                <UserImage
                  src={community_member.avatar_url}
                  name={community_member.name}
                  size="10"
                />
              </a>
            </div>
          </div>
        )}
        <div
          className={classnames("post--card__body p-5", {
            "post-meta--hidden": hide_meta_info,
          })}
        >
          <div className="post--card__content">
            <div className="post--card__header">
              <h2 className="space-x-2 break-words text-base font-semibold">
                <ShowPageLink
                  post={post}
                  className="!text-darkest focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  shouldOpenPostModal
                >
                  {truncate(display_title, { length: 60 })}
                </ShowPageLink>
                {!!flagged_for_approval_at && (
                  <BadgeV2
                    className="post--card__headline-tag !bg-author-in-review border-0 align-text-top uppercase text-white"
                    label={t("in_review")}
                  />
                )}
              </h2>
            </div>
            {!hide_meta_info && (
              <div className="post--card__meta">
                <CardViewItemLastRepliedOrPosted post={post} />
              </div>
            )}
          </div>
        </div>
      </div>
      <EngagementActions />
      <div className="post--card__actions-wrapper absolute right-4 top-4">
        <PostActionsDropdownMenu
          onPostDestroy={onPostDestroy}
          refetchPosts={refetchPosts}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
