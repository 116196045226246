import PropTypes from "prop-types";
import { noop } from "lodash";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useImagePostModal } from "@/react/components/Modals/ImagePostModal";
import { isBasicPost, isImagePost } from "@/react/helpers/postHelpers";
import { isCardViewSpace } from "@/react/helpers/spaceHelpers";
import { postApi } from "@circle-react/api";
import { useDuplicateImagePostModal } from "@circle-react/components/Modals/DuplicateImagePostModal";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { useEditPostV2Modal } from "@circle-react/components/QuickPostV2/EditPostModal";
import {
  useCurrentPostContext,
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { hasFeaturedAreas } from "@circle-react/helpers/communityHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { afterSaveRedirectPath } from "@circle-react/helpers/postHelpers";
import { flaggedContent } from "@circle-react/helpers/urlHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { PostShareOptions } from "../../PostShareOptions";
import {
  CloseCommentsAction,
  DisableCommentsAction,
  FeaturedAreaVisibilityAction,
  FollowAction,
  PinToSidebarAction,
  PinToTopAction,
} from "../Actions";
import { DuplicatePostModal } from "../DuplicatePostModal";
import { EditPostModal } from "../EditPostModal";
import { ReportPostModal } from "../ReportPostModal";

/**
 * @deprecated
 * Please use the new PostDropdownActions component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostDropdownActions = ({
  refetchPosts,
  currentCommunity,
  onPostDestroy,
  onChange,
}) => {
  const history = useHistory();
  const {
    post,
    showModerationInActionsDropdown,
    canManagePost,
    canUpdatePost,
    canDestroyPost,
    canDuplicatePost,
    canReportPost,
    refetchPostDetails,
    showShareOnWebOptions,
  } = useCurrentPostHelpers();
  const { usedIn } = useCurrentPostContext();

  const [showEditPostModal, toggleEditPostModal] = useBoolean(false);
  const [showDuplicatePostModal, toggleDuplicatePostModal] = useBoolean(false);
  const [showReportPostModal, toggleReportPostModal] = useBoolean(false);
  const [showConfirmationModal, toggleConfirmationModal] = useBoolean(false);
  const {
    currentCommunitySettings,
    isViewOnlyMasquerading,
    currentCommunityMember,
  } = usePunditUserContext();
  const quickPostV2Enabled = currentCommunitySettings?.quick_post_v2_enabled;
  const { show: showEditPostV2Modal } = useEditPostV2Modal();
  const imagePostModal = useImagePostModal();
  const duplicateImagePostModal = useDuplicateImagePostModal();

  const isSmScreen = useSmScreenMediaQuery();
  const isPostImagePost = isImagePost(post);
  const isPostBasicPost = isBasicPost(post);
  const { contentPortalElement, onClose: onCloseCurrentModal } = useModalData();

  const { success, error } = useToast();
  const { data: space, refetch } = useCurrentSpaceContext();
  const shouldShowPinToSidebar =
    isPostBasicPost &&
    Boolean(!space?.hide_right_sidebar) &&
    !isCardViewSpace(space);
  const isKbPostActionEnabled =
    currentCommunitySettings?.kb_post_action_enabled &&
    isCommunityAdmin(currentCommunityMember);

  const deleteMutation = useMutation(() => postApi.destroy({ post }), {
    onSuccess: () => {
      onPostDestroy(post.id);
      success(t("post_dropdown_actions.delete_successful"));
      refetch();
    },
    onError: () => error(t("post_dropdown_actions.delete_failed")),
  });

  const updateKnowledgeBasePostMutation = useMutation(
    () => postApi.updateKnowledgeBasePost({ post }),
    {
      onSuccess: () => {
        success(
          t("post_dropdown_actions.update_knowledge_base_post_successful"),
        );
      },
      onError: () =>
        error(t("post_dropdown_actions.update_knowledge_base_post_failed")),
    },
  );

  const redirectToPost = post => history.push(afterSaveRedirectPath({ post }));

  if (isViewOnlyMasquerading) {
    return (
      <IconButton
        name="16-menu-dots-horizontal"
        iconClassName="!text-dark cursor-not-allowed"
        aria-label={t("post_actions")}
        dataTestId="action-more"
        disabled
      />
    );
  }

  return (
    <>
      <Dropdown
        direction="bottom-end"
        button={
          <IconButton
            name="16-menu-dots-horizontal"
            iconClassName="!text-dark"
            aria-label={t("post_actions")}
            dataTestId="action-more"
          />
        }
        className="z-10 ml-0 md:ml-1"
        transitionWrapperClassName="z-50"
        size="large"
        buttonLabel={t("post_actions")}
        appendTo={contentPortalElement ?? document.body}
      >
        <BookmarkButton
          variant="dropdown"
          bookmarkType="post"
          record={post}
          onSuccess={refetchPostDetails}
        />
        {canUpdatePost && isPostBasicPost && (
          <Dropdown.ItemLink
            onClick={() => {
              if (isViewOnlyMasquerading) {
                noop();
              } else {
                quickPostV2Enabled
                  ? showEditPostV2Modal({
                      post,
                      onPostChange: onChange,
                    })
                  : toggleEditPostModal();
              }
            }}
          >
            {t("edit_post")}
          </Dropdown.ItemLink>
        )}
        {canUpdatePost && isPostImagePost && (
          <Dropdown.ItemLink
            onClick={() => {
              void imagePostModal.show({
                spaceId: space.id,
                onClose: () => imagePostModal.remove(),
                onPostChange: onChange,
                currentPost: post,
                shouldSkipRedirectAfterSubmit: usedIn === "full-post-modal",
              });
            }}
          >
            {t("edit_post")}
          </Dropdown.ItemLink>
        )}
        {showShareOnWebOptions &&
          (isSmScreen || usedIn === "full-post-modal") && (
            <PostShareOptions
              onChange={onChange}
              className="w-full"
              buttonClassName="w-full"
              button={
                <Dropdown.ItemLink>{t("post.share.title")}</Dropdown.ItemLink>
              }
              shouldRenderCustomMenuButton={
                isSmScreen || usedIn === "full-post-modal"
              }
            />
          )}
        {isPostBasicPost && canDuplicatePost && (
          <Dropdown.ItemLink onClick={toggleDuplicatePostModal}>
            {t("duplicate_post")}
          </Dropdown.ItemLink>
        )}
        {isPostImagePost && canDuplicatePost && (
          <Dropdown.ItemLink
            onClick={() => {
              if (usedIn === "full-post-modal") {
                onCloseCurrentModal();
              }
              void duplicateImagePostModal.show({
                post,
                onSuccess: redirectToPost,
              });
            }}
          >
            {t("duplicate_post")}
          </Dropdown.ItemLink>
        )}
        {canReportPost && (
          <Dropdown.ItemLink
            onClick={isViewOnlyMasquerading ? noop : toggleReportPostModal}
          >
            {t("report_post")}
          </Dropdown.ItemLink>
        )}
        {showModerationInActionsDropdown && (
          <Dropdown.ItemWithLink to={flaggedContent.index()}>
            {t("flagged_contents.review")}
          </Dropdown.ItemWithLink>
        )}
        {canDestroyPost && (
          <Dropdown.ItemLink
            onClick={isViewOnlyMasquerading ? noop : toggleConfirmationModal}
          >
            <span className="text-v2-danger">{t("delete_post")}</span>
          </Dropdown.ItemLink>
        )}
        {isKbPostActionEnabled && (
          <Dropdown.ItemLink
            onClick={() => {
              updateKnowledgeBasePostMutation.mutate();
            }}
          >
            {t(
              "post_dropdown_actions.update_knowledge_base_post_dropdown_label",
            )}
          </Dropdown.ItemLink>
        )}
        {(canUpdatePost ||
          canDuplicatePost ||
          canReportPost ||
          showModerationInActionsDropdown ||
          canDestroyPost) && <Dropdown.Separator />}
        <FollowAction post={post} onChange={onChange} />
        {canManagePost && (
          <>
            <PinToTopAction
              post={post}
              onChange={onChange}
              refetchPosts={refetchPosts}
            />
            {shouldShowPinToSidebar && (
              <PinToSidebarAction
                post={post}
                onChange={onChange}
                refetchPosts={refetchPosts}
              />
            )}
            <DisableCommentsAction post={post} onChange={onChange} />
            {post.is_comments_enabled && (
              <CloseCommentsAction post={post} onChange={onChange} />
            )}
            {hasFeaturedAreas(currentCommunity) && (
              <FeaturedAreaVisibilityAction
                post={post}
                community={currentCommunity}
                onChange={onChange}
                refetchPosts={refetchPosts}
              />
            )}
          </>
        )}
      </Dropdown>
      <EditPostModal
        show={showEditPostModal}
        closeModal={toggleEditPostModal}
        post={post}
        onChange={onChange}
      />
      <DuplicatePostModal
        spaceId={post.space_id}
        postId={post.id}
        postSlug={post.slug}
        postTitle={post.name}
        isOpen={showDuplicatePostModal}
        onClose={toggleDuplicatePostModal}
      />
      <ReportPostModal
        post={post}
        show={showReportPostModal}
        onClose={toggleReportPostModal}
      />
      <ConfirmationModal
        isOpen={showConfirmationModal}
        title={t("delete_post_confirmation")}
        onClose={toggleConfirmationModal}
        onCancel={toggleConfirmationModal}
        onConfirm={deleteMutation.mutate}
        confirmVariant="danger"
        disabled={deleteMutation.isLoading}
        confirmText={deleteMutation.isLoading ? t("deleting") : t("confirm")}
      >
        {t("delete_post_descriptor")}
      </ConfirmationModal>
    </>
  );
};

PostDropdownActions.propTypes = {
  onChange: PropTypes.func,
  refetchPosts: PropTypes.func,
  currentCommunity: PropTypes.object.isRequired,
  onPostDestroy: PropTypes.func,
};
