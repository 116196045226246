import type { ReactNode } from "react";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { PostShareDropdown } from "@/react/components/PostsV2/PostViewListItem/PostShareDropdown";
import { useCurrentPostHelpers } from "@/react/components/PostsV2/useCurrentPostHelpers";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import type { Post } from "@circle-react/types/Post";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";

export interface PostShareOptionsProps {
  onChange?: (data: Post) => void;
  button?: ReactNode;
  shouldRenderCustomMenuButton?: boolean;
}

/**
 * @deprecated
 * Please use the new PostShareOptions component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostShareOptions = ({
  onChange,
  button,
  shouldRenderCustomMenuButton,
}: PostShareOptionsProps) => {
  const { showShareOnWebOptions, post, getSharePostLink, sharePostData } =
    useCurrentPostHelpers({ onChangeSharePost: onChange });

  const { data: space } = useCurrentSpaceContext();

  const Component: ReactNode = button || <Icon type="16-share" size={20} />;

  if (!showShareOnWebOptions(space)) {
    return null;
  }

  return (
    <Dropdown
      size="extra-large"
      direction="bottom-end"
      className={classnames("z-10 w-full md:w-auto", {
        "ml-1": !shouldRenderCustomMenuButton,
      })}
      buttonClassName={classnames("w-full md:w-auto", {
        "md:!w-full": shouldRenderCustomMenuButton,
      })}
      button={
        shouldRenderCustomMenuButton ? (
          Component
        ) : (
          <Dropdown.MenuButton className="!pt-0">
            <Typography.LabelSm
              weight="medium"
              color="text-dark hover:text-default"
            >
              {t("post.share.title")}
            </Typography.LabelSm>
          </Dropdown.MenuButton>
        )
      }
    >
      <PostShareDropdown
        post={post}
        getSharePostLink={getSharePostLink}
        sharePostData={sharePostData}
      />
    </Dropdown>
  );
};
