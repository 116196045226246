import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useCurrentPostContext } from "@/react/contexts";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/Modal";

const REPORT_REASONS = [
  "harassment",
  "spam",
  "incorrect_location",
  "against_guidelines",
  "other",
];

/**
 * @deprecated
 * Please use the new ReportModal component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const ReportModal = ({ show, onClose, onReportSubmit }) => {
  const { usedIn } = useCurrentPostContext();

  const reportReasonOptions = REPORT_REASONS.map(reason => ({
    value: reason,
    label: t(`flagged_contents.report_reasons.${reason}`),
  }));

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("comments.report.form.title")}
      containerClassName={usedIn === "full-post-modal" && "!z-[1061]"}
    >
      <Form onSubmit={onReportSubmit}>
        <Form.Item
          name="reported_reason_type"
          label={t("flagged_contents.form.reported_reason_type")}
          description={t(
            "flagged_contents.form.reported_reason_type_description",
          )}
          hideBorder
        >
          <Form.SelectNative options={reportReasonOptions} />
        </Form.Item>
        <Form.Item
          name="reported_reason_body"
          label={t("flagged_contents.form.reported_reason_body")}
          description={t(
            "flagged_contents.form.reported_reason_body_description",
          )}
          hideBorder
          fullWidth
        >
          <Form.TextArea />
        </Form.Item>
        <Button type="submit" variant="primary" formButton>
          {t("report")}
        </Button>
      </Form>
    </Modal>
  );
};

ReportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReportSubmit: PropTypes.func.isRequired,
};
