import { useCallback, useEffect, useRef, useState } from "react";
import { noop } from "lodash";
import { useQuery } from "react-query";
import { useClickAway } from "react-use";
import { t } from "@/i18n-js/instance";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";
import { Icon } from "@circle-react-shared/Icon";
import { Modal } from "@circle-react-uikit/ModalV2";
import { EmptySearch } from "./EmptySearch";
import { NoResults } from "./NoResults";
import { SearchResult } from "./SearchResult";

export const SearchComponent = () => {
  const drawer = useModal();
  const modalBodyRef = useRef<any>(null);
  const resultsContainerRef = useRef<any>(null);

  useClickAway(modalBodyRef, () => {
    drawer.remove();
  });

  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState<any[]>([]);
  const [hasNoResults, setHasNoResults] = useState(false);

  const resetSearch = useCallback(() => {
    setSearchValue("");
    setResults([]);
    setHasNoResults(false);
  }, []);

  const debouncedSearchValue = useDebouncedValue(searchValue, 500);

  const { data, isLoading, refetch } = useQuery<any>(
    internalApi.masquerading.entities.index({
      query: debouncedSearchValue,
    }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  useEffect(() => {
    if (debouncedSearchValue) {
      void refetch();
    }
  }, [debouncedSearchValue, refetch]);

  useEffect(() => {
    if (searchValue && data?.records) {
      const results = data.records;
      setResults(results);
      setHasNoResults(results.length === 0);
    } else {
      setResults([]);
      setHasNoResults(false);
    }
  }, [data, searchValue]);

  return (
    <Modal isOpen={drawer.visible} onClose={drawer.remove}>
      <Modal.Content
        position="top"
        className="border-primary w-full rounded-[0px] sm:rounded-lg sm:border md:!mt-[3.25rem] md:w-[335px]"
        size="xl"
      >
        <div ref={modalBodyRef}>
          <div className="bg-primary sticky top-0 isolate z-10 flex items-center justify-between p-4 md:static md:top-auto md:p-0">
            <div className="mr-0 flex w-full items-center md:p-5">
              <div className="bg-secondary sm:bg-primary border-secondary flex w-full items-center gap-3 rounded-md border px-2 py-1 sm:rounded-md sm:px-2 sm:py-1">
                <div className="flex">
                  {isLoading ? (
                    <Icon
                      type="loader"
                      className="text-default"
                      size={20}
                      useWithFillCurrentColor
                    />
                  ) : (
                    <Icon
                      type="16-search"
                      className="text-default"
                      size={16}
                      useWithFillCurrentColor
                    />
                  )}
                </div>
                <input
                  className="text-dark sm:bg-primary placeholder:text-light h-6 w-full rounded bg-transparent text-sm outline-none placeholder:opacity-100 sm:rounded-none"
                  placeholder={t("view_only_masquerading.input_placeholder")}
                  aria-autocomplete="list"
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                />
                <button
                  type="submit"
                  className="text-default flex pt-1"
                  onClick={resetSearch}
                >
                  {!!searchValue && (
                    <Icon type="20-close-md" useWithFillCurrentColor />
                  )}
                </button>
              </div>
            </div>
          </div>
          {results.length > 0 ? (
            <div
              className="overflow-y-auto md:max-h-96"
              ref={resultsContainerRef}
            >
              <ul className="list-none">
                {results.map(result => (
                  <SearchResult
                    key={`community-member-${result.id}`}
                    id={result.id}
                    type={result.type}
                    name={result.name}
                    avatarUrl={result.avatar_url}
                    onClick={noop}
                  />
                ))}
              </ul>
            </div>
          ) : hasNoResults ? (
            <NoResults />
          ) : (
            <EmptySearch />
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};
