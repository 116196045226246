import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "@/i18n-js/instance";
import { PostItem as PostV3Item } from "@/react/components/PostsV3/PostViewList/PostItem";
import { PostNavigationProvider } from "@circle-react/components/PostsV3/PostViewList/PostNavigationContext";
import { PROFILE_MODAL_CONTAINER_ID } from "@circle-react/components/ProfileModalV3/Body";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { CurrentSpaceContextProvider } from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { useProfilePostsList } from "@circle-react/hooks/profile/useProfilePostsList";
import EmptyState from "../EmptyState";

export const ProfilePosts = () => {
  const { member, isFullPageView } = useProfileContext();
  const { fetchNextPage, posts, data, hasNextPage, isLoading, onPostChange } =
    useProfilePostsList({
      memberPublicId: member.public_uid,
    });

  if (isLoading) {
    return <LoaderRow />;
  }

  if (posts.length < 1)
    return (
      <EmptyState
        title={t("profile_drawer.posts.empty_state.title")}
        subtitle={t("profile_drawer.posts.empty_state.subtitle")}
      />
    );

  const perPagePosts: any = data?.pages.map((page: any) =>
    page.records.map((post: any) => post.id),
  );

  return (
    <PostsDetailsContextProvider perPagePostIds={perPagePosts}>
      <PostNavigationProvider hasNextPage={!!hasNextPage}>
        <InfiniteScroll
          style={{ overflow: "initial" }}
          scrollThreshold={0.5}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={<LoaderRow />}
          dataLength={posts.length}
          scrollableTarget={
            isFullPageView ? "panel" : PROFILE_MODAL_CONTAINER_ID
          }
          className="space-y-2"
        >
          {posts.map(post => (
            <CurrentSpaceContextProvider key={post.id} spaceId={post.space_id}>
              <PostV3Item
                key={post.id}
                onPostChange={onPostChange}
                post={post}
                shouldForceSamePageInteractions
                className="!rounded-2xl"
              />
            </CurrentSpaceContextProvider>
          ))}
        </InfiniteScroll>
      </PostNavigationProvider>
    </PostsDetailsContextProvider>
  );
};
