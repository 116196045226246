import classNames from "classnames";
import { noop } from "lodash";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { useImagePostModal } from "@/react/components/Modals/ImagePostModal";
import { isBasicPost, isImagePost } from "@/react/helpers/postHelpers";
import { postApi } from "@circle-react/api";
import { useDuplicateImagePostModal } from "@circle-react/components/Modals/DuplicateImagePostModal";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useEditPostV2Modal } from "@circle-react/components/QuickPostV2/EditPostModal";
import {
  useCurrentPostContext,
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { hasFeaturedAreas } from "@circle-react/helpers/communityHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { flaggedContent } from "@circle-react/helpers/urlHelpers";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { PostShareOptions } from "../../PostShareOptions";
import {
  CloseCommentsAction,
  DisableCommentsAction,
  FeaturedAreaVisibilityAction,
  FollowAction,
  PinToSidebarAction,
  PinToTopAction,
} from "../Actions";
import { DisableLikesAction } from "../DisableLikesAction";
import { DuplicatePostModal } from "../DuplicatePostModal";
import { EditPostModal } from "../EditPostModal";
import { ReportPostModal } from "../ReportPostModal";
import { CardViewThumbnailModal } from "./CardViewThumbnailModal";
import { usePostDropdownActions } from "./usePostDropdownActions";

export const PostDropdownActions = ({
  refetchPosts,
  currentCommunity,
  onPostDestroy,
  onChange,
}) => {
  const {
    post,
    showModerationInActionsDropdown: shouldShowModerationInActionsDropdown,
    canManagePost,
    canUpdatePost,
    canDestroyPost,
    canDuplicatePost,
    canReportPost,
    refetchPostDetails,
  } = useCurrentPostHelpers();
  const { usedIn, isUsedInMinimalTipTapViewModal } = useCurrentPostContext();
  const {
    currentCommunitySettings,
    isViewOnlyMasquerading,
    currentCommunityMember,
  } = usePunditUserContext();
  const quickPostV2Enabled = currentCommunitySettings?.quick_post_v2_enabled;
  const { show: showEditPostV2Modal } = useEditPostV2Modal();
  const imagePostModal = useImagePostModal();
  const duplicateImagePostModal = useDuplicateImagePostModal();
  const isPostImagePost = isImagePost(post);
  const isPostBasicPost = isBasicPost(post);
  const { contentPortalElement, onClose: onCloseCurrentModal } = useModalData();
  const { data: space } = useCurrentSpaceContext();
  const isKbPostActionEnabled =
    currentCommunitySettings?.kb_post_action_enabled &&
    isCommunityAdmin(currentCommunityMember);

  const { success, error } = useToast();
  const updateKnowledgeBasePostMutation = useMutation(
    () => postApi.updateKnowledgeBasePost({ post }),
    {
      onSuccess: () => {
        success(
          t("post_dropdown_actions.update_knowledge_base_post_successful"),
        );
      },
      onError: () =>
        error(t("post_dropdown_actions.update_knowledge_base_post_failed")),
    },
  );

  const {
    shouldShowPinToSidebar,
    shouldShowShareOnWebOptions,
    shouldShowSetThumbnailOptionForPosts,
    shouldShowEditPostModal,
    toggleEditPostModal,
    shouldShowDuplicatePostModal,
    toggleDuplicatePostModal,
    shouldShowReportPostModal,
    toggleReportPostModal,
    shouldShowConfirmationModal,
    toggleConfirmationModal,
    deleteMutation,
    redirectToPost,
    shouldShowSetThumbnailModal,
    toggleSetThumbnailModal,
  } = usePostDropdownActions({ onPostDestroy });

  if (isViewOnlyMasquerading) {
    return (
      <IconButton
        name="16-menu-dots-horizontal"
        iconClassName="!text-dark cursor-not-allowed"
        aria-label={t("post_actions")}
        dataTestId="action-more"
        disabled
      />
    );
  }

  return (
    <>
      <Dropdown
        direction="bottom-end"
        menuButtonEl="div"
        button={
          <IconButton
            name="20-menu-dots-horizontal"
            iconClassName="!text-default group-hover:!text-dark !w-5 !h-5"
            className="group"
            variant="secondary"
            iconSize={20}
            buttonSize={28}
            aria-label={t("post_actions")}
            dataTestId="action-more"
          />
        }
        className={classNames("z-10", {
          "inline-flex h-6 w-6": isUsedInMinimalTipTapViewModal,
        })}
        transitionWrapperClassName="z-50"
        size="large"
        buttonLabel={t("post_actions")}
        appendTo={contentPortalElement ?? document.body}
      >
        <BookmarkButton
          variant="dropdown"
          bookmarkType="post"
          record={post}
          onSuccess={refetchPostDetails}
        />
        {shouldShowShareOnWebOptions && (
          <PostShareOptions
            onChange={onChange}
            className="w-full"
            buttonClassName="w-full"
            button={
              <Dropdown.ItemLink>
                {t("post.share.share_post_actions_label")}
              </Dropdown.ItemLink>
            }
            shouldRenderCustomMenuButton
          />
        )}
        {canUpdatePost && isPostBasicPost && (
          <Dropdown.ItemLink
            onClick={() => {
              if (isViewOnlyMasquerading) {
                noop();
              } else {
                quickPostV2Enabled
                  ? showEditPostV2Modal({
                      post,
                      onPostChange: onChange,
                      shouldSkipRedirectAfterSubmit:
                        isUsedInMinimalTipTapViewModal,
                    })
                  : toggleEditPostModal();
              }
            }}
          >
            {t("edit_post")}
          </Dropdown.ItemLink>
        )}
        {canUpdatePost && isPostImagePost && (
          <Dropdown.ItemLink
            onClick={() => {
              void imagePostModal.show({
                spaceId: space.id,
                onClose: () => imagePostModal.remove(),
                onPostChange: onChange,
                currentPost: post,
                shouldSkipRedirectAfterSubmit: usedIn === "full-post-modal",
              });
            }}
          >
            {t("edit_post")}
          </Dropdown.ItemLink>
        )}
        {isPostBasicPost && canDuplicatePost && (
          <Dropdown.ItemLink onClick={toggleDuplicatePostModal}>
            {t("duplicate_post")}
          </Dropdown.ItemLink>
        )}
        {isPostImagePost && canDuplicatePost && (
          <Dropdown.ItemLink
            onClick={() => {
              if (usedIn === "full-post-modal") {
                onCloseCurrentModal();
              }
              void duplicateImagePostModal.show({
                post,
                onSuccess: redirectToPost,
              });
            }}
          >
            {t("duplicate_post")}
          </Dropdown.ItemLink>
        )}
        {shouldShowSetThumbnailOptionForPosts && (
          <Dropdown.ItemLink
            onClick={() => {
              toggleSetThumbnailModal();
            }}
          >
            {t("set_thumbnail")}
          </Dropdown.ItemLink>
        )}
        {canReportPost && (
          <Dropdown.ItemLink
            onClick={isViewOnlyMasquerading ? noop : toggleReportPostModal}
          >
            {t("report_post")}
          </Dropdown.ItemLink>
        )}
        {shouldShowModerationInActionsDropdown && (
          <Dropdown.ItemWithLink to={flaggedContent.index()}>
            {t("flagged_contents.review")}
          </Dropdown.ItemWithLink>
        )}
        {canDestroyPost && (
          <Dropdown.ItemLink
            onClick={isViewOnlyMasquerading ? noop : toggleConfirmationModal}
          >
            <span className="text-v2-danger">{t("delete_post")}</span>
          </Dropdown.ItemLink>
        )}
        {isKbPostActionEnabled && (
          <Dropdown.ItemLink
            onClick={() => {
              updateKnowledgeBasePostMutation.mutate();
            }}
          >
            {t(
              "post_dropdown_actions.update_knowledge_base_post_dropdown_label",
            )}
          </Dropdown.ItemLink>
        )}
        {(canUpdatePost ||
          canDuplicatePost ||
          canReportPost ||
          shouldShowModerationInActionsDropdown ||
          canDestroyPost) && <Dropdown.Separator />}
        <FollowAction post={post} onChange={onChange} />
        {canManagePost && (
          <>
            <PinToTopAction
              post={post}
              onChange={onChange}
              refetchPosts={refetchPosts}
            />
            {shouldShowPinToSidebar && (
              <PinToSidebarAction
                post={post}
                onChange={onChange}
                refetchPosts={refetchPosts}
              />
            )}
            <DisableLikesAction post={post} onChange={onChange} />
            <DisableCommentsAction post={post} onChange={onChange} />
            {post.is_comments_enabled && (
              <CloseCommentsAction post={post} onChange={onChange} />
            )}
            {hasFeaturedAreas(currentCommunity) && (
              <FeaturedAreaVisibilityAction
                post={post}
                community={currentCommunity}
                onChange={onChange}
                refetchPosts={refetchPosts}
              />
            )}
          </>
        )}
      </Dropdown>
      <CardViewThumbnailModal
        post={post}
        onChange={onChange}
        onClose={toggleSetThumbnailModal}
        isOpen={shouldShowSetThumbnailModal}
      />
      <EditPostModal
        show={shouldShowEditPostModal}
        closeModal={toggleEditPostModal}
        post={post}
        onChange={onChange}
      />
      <DuplicatePostModal
        spaceId={post.space_id}
        postId={post.id}
        postSlug={post.slug}
        postTitle={post.name}
        isOpen={shouldShowDuplicatePostModal}
        onClose={toggleDuplicatePostModal}
      />
      <ReportPostModal
        post={post}
        show={shouldShowReportPostModal}
        onClose={toggleReportPostModal}
      />
      <ConfirmationModal
        isOpen={shouldShowConfirmationModal}
        title={t("delete_post_confirmation")}
        onClose={toggleConfirmationModal}
        onCancel={toggleConfirmationModal}
        onConfirm={deleteMutation.mutate}
        confirmVariant="danger"
        disabled={deleteMutation.isLoading}
        confirmText={deleteMutation.isLoading ? t("deleting") : t("confirm")}
      >
        {t("delete_post_descriptor")}
      </ConfirmationModal>
    </>
  );
};
