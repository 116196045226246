import PropTypes from "prop-types";
import { ProgressiveImage } from "@circle-react/components/shared/ProgressiveImage";
import { ShowPageLink } from "./ShowPageLink";

/**
 * @deprecated
 * Please use the new PostCoverImage component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostCoverImage = ({ post, ...rest }) => {
  if (!post.cover_image_variants?.large) {
    return null;
  }

  return (
    <div className="px-5 pt-5 md:p-0">
      <ShowPageLink post={post} className="post__cover" {...rest}>
        <ProgressiveImage
          key={post.cover_image_url}
          src={post.cover_image_url}
          srcVariants={post.cover_image_variants}
          alt={I18n.t("post.cover_image_alt")}
        />
      </ShowPageLink>
    </div>
  );
};

PostCoverImage.propTypes = {
  post: PropTypes.shape({
    cover_image_url: PropTypes.string,
    cover_image_variants: PropTypes.shape({
      large: PropTypes.string,
    }),
  }).isRequired,
};
