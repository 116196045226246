import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../../Common/FormItem";

export const Permissions = () => {
  const permissionsFields = [
    "hide_meta_info",
    "is_comments_disabled",
    "is_liking_disabled",
  ];

  return (
    <div className="mb-6 md:mb-12">
      <div className="mb-0 mt-6 md:mb-3 md:mt-8">
        <Typography.TitleMd>
          {t("events.edit_modal.advanced_options.permissions")}
        </Typography.TitleMd>
      </div>
      {permissionsFields.map(fieldName => (
        <FormItem
          key={fieldName}
          name={fieldName}
          tooltipText={t(`events.edit_modal.${fieldName}_tooltip`)}
          inlineReverse
          className="!pb-0"
        >
          <Form.ToggleSwitch variant="small" />
        </FormItem>
      ))}
    </div>
  );
};
