import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { TiptapEdit } from "@circle-react/components/CommentsV2/Comment/EditModal/TiptapEdit";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Content } from "./Content";
import { useEditForm } from "./useEditForm";
import "./styles.scss";

const EditModalComponent = ({ space, comment, onUpdate }: any) => {
  const { id: spaceId, is_private: spaceIsPrivate } = space;
  const modal = useModal();
  const isTiptap = comment.editor === "tiptap";

  const { defaultValues, updateComment }: any = useEditForm({
    comment,
    onUpdate: (commentData: any) => {
      onUpdate(commentData);
      void modal.hide();
    },
  });

  return (
    <Modal
      title={t("comments.edit.form.title")}
      isOpen={modal.visible}
      onClose={noop}
    >
      {isTiptap ? (
        <TiptapEdit comment={comment} updateComment={updateComment} />
      ) : (
        <Form
          defaultValues={defaultValues}
          onSubmit={updateComment}
          className="h-full"
          formClassName="h-full"
        >
          <Modal.Overlay />
          <Content
            spaceId={spaceId}
            spaceIsPrivate={spaceIsPrivate}
            onClose={modal.hide}
          />
        </Form>
      )}
    </Modal>
  );
};

const EditModal = ModalManager.create(({ space, comment, onUpdate }: any) => (
  <EditModalComponent space={space} comment={comment} onUpdate={onUpdate} />
));

/**
 * @deprecated
 * Please use the new useEditModal hook from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */
export const useEditModal = () => useModal(EditModal);
