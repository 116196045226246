import { t } from "@/i18n-js/instance";
import { toLocaleString } from "@circle-react/helpers/number";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

interface CommentCountLinkProps {
  count: number;
  toggleComment: () => void;
  hasPostDetailsLoaded: boolean;
}

/**
 * @deprecated
 * Please use the new CommentCountLink component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentCountLink = ({
  count,
  toggleComment,
  hasPostDetailsLoaded,
}: CommentCountLinkProps) => {
  if (!hasPostDetailsLoaded) {
    return <SkeletonLoader variant="row" lg long />;
  }

  return (
    <button
      type="button"
      className="hover:!text-dark focus-visible:!text-dark transition-colors duration-150 ease-in-out"
      onClick={toggleComment}
    >
      {t("post.comments", { count, formatted_count: toLocaleString(count) })}
    </button>
  );
};
