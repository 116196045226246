import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { canManageSpace } from "@/react/helpers/spaceHelpers";
import { QUICK_POST_THUMBNAIL_BUTTON_PORTAL_ID } from "@circle-react/components/QuickPostV2/Content/Header";
import { FormTopicsPicker } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/FormTopicsPicker";
import { hasSpacesFindByIdHelper } from "@circle-react/components/QuickPostV2/Settings/Form";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { FormTextAreaAutosize } from "@circle-react/components/shared/uikit/Form/FormTextAreaAutosize";
import { FormTipTapBlockEditor } from "@circle-react/components/shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { IconButton } from "@circle-react/components/shared/uikit/HeaderV3/IconButton";
import {
  useCurrentPostContext,
  usePunditUserContext,
  useSpacesContext,
} from "@circle-react/contexts";
import {
  showCoverImageSelector,
  showTitleInput,
} from "@circle-react/helpers/postHelpers";
import { useFocusOnLoad } from "@circle-react/hooks/useFocusOnLoad";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { Portal } from "@circle-react-uikit/Portal";
import { RichTextInput } from "@circle-react-uikit/RichTextInput";
import { Toolbar } from "@circle-react-uikit/TipTapBlockEditor/Toolbar";

export const Body = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const tiptapPostEnabled = currentCommunitySettings?.tiptap_post_enabled;
  const { watch, setValue } = useFormContext();
  const sgidToObjectMapPath = "tiptap_body.sgids_to_object_map";
  const sgidToObjectMap = watch("tiptap_body.sgids_to_object_map") || {};
  const postName = watch("name");
  const handleMapChange = (localSgidToObjectMap: any) => {
    const newMap = { ...sgidToObjectMap, ...localSgidToObjectMap };
    setValue(sgidToObjectMapPath, newMap);
  };

  const inlineAttachmentsPath = "tiptap_body.inline_attachments";
  const inlineAttachments = watch(inlineAttachmentsPath) || [];
  const handleLocalAttachmentsChange = (newAttachments: any) =>
    setValue(inlineAttachmentsPath, [...inlineAttachments, ...newAttachments]);

  const { record: post, helpers } = useCurrentPostContext();
  const isTiptapPost = Boolean(helpers?.isTiptapPost);
  const focusRef = useFocusOnLoad<HTMLTextAreaElement>();
  const { id: postId, tiptap_body: tiptapBody } = post || {};
  const isEdit = Boolean(postId);
  const shouldShowTipTap = isEdit ? isTiptapPost : tiptapPostEnabled;
  const spaceId = watch("space_id");
  const coverImage = watch("cover_image");
  const spaces = useSpacesContext();
  let space;
  if (hasSpacesFindByIdHelper(spaces)) {
    space = spaces.helpers.findById(spaceId);
  }
  const canCreateTopics = space && canManageSpace(space);

  return (
    <div className="flex-1 overflow-auto px-5 py-4" data-ai-scrollable>
      <div className="trix-v2 mx-auto grid h-full max-w-3xl grid-rows-[auto,auto,1fr]">
        <div>
          {showCoverImageSelector(space) && (
            <CoverImageInput
              name="cover_image"
              className={classNames("relative", {
                "mb-2": Boolean(coverImage),
              })}
              showAddCoverBtn
              aspectRatio={ASPECT_RATIO.DEFAULT}
              changeImageText={t("replace")}
              customAddCoverBtn={
                <Portal
                  selectorQuery={`#${QUICK_POST_THUMBNAIL_BUTTON_PORTAL_ID}`}
                >
                  <TippyV2
                    placement="bottom"
                    content={t("quick_post_v2.header.add_cover")}
                  >
                    <IconButton
                      variant="secondary"
                      iconSize={20}
                      iconClassName="!text-dark"
                      name="20-cover-image"
                      ariaLabel={t("quick_post_v2.header.add_cover")}
                    />
                  </TippyV2>
                </Portal>
              }
            />
          )}
        </div>
        <div>
          {showTitleInput(space) && (
            <FormTextAreaAutosize
              maxLength={255}
              draggable={false}
              name="name"
              autoFocus
              ref={focusRef}
              placeholder={t("quick_post_v2.title_placeholder")}
              className="bg-primary text-dark mb-2 block w-full resize-none border-none p-0 text-2xl font-bold focus:ring-0"
              rows={1}
              onKeyPress={e => {
                if (e.key === "Enter") e.preventDefault();
              }}
              tabIndex={0}
              data-testid="post_name"
            />
          )}
        </div>
        {/* This div element ensures that Tiptap's BubbleMenu is wrapped with a
        container element, preventing DOMException when hiding the post title.
        See: https://github.com/ueberdosis/tiptap/issues/3784 */}
        <div>
          {shouldShowTipTap ? (
            <FormTipTapBlockEditor
              placeholder={t("quick_post_v2.body_placeholder")}
              name="tiptap_body.body"
              toolbars={[
                {
                  portalId: "quick-post-v2-footer-toolbar",
                  component: Toolbar,
                },
              ]}
              className="h-full"
              editorClassName="h-full quick-post-modal--v2"
              spaceId={spaceId}
              sgidToObjectMap={tiptapBody?.sgids_to_object_map}
              inlineAttachments={tiptapBody?.inline_attachments}
              onLocalSgidToObjectMapChange={handleMapChange}
              onLocalAttachmentsChange={handleLocalAttachmentsChange}
              aiCopilotTitle={postName}
            />
          ) : (
            <RichTextInput
              id="post_body"
              name="body"
              is_private_space={false}
              space_id={spaceId}
              placeholder={t("quick_post_v2.body_placeholder")}
              tabIndex={0}
              toolbarPortalId="quick-post-v2-footer-toolbar"
            />
          )}
        </div>
        {!!spaceId && (
          <Portal selectorQuery="#topics-picker-portal">
            <FormTopicsPicker
              containerClassName="border-t border-primary"
              name="topics"
              spaceId={spaceId}
              creatable={canCreateTopics ?? false}
            />
          </Portal>
        )}
      </div>
    </div>
  );
};
