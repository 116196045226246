import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { useThreads } from "@circle-react/hooks/chatsV2/useThreads";
import { Typography } from "@circle-react-uikit/Typography";
import { ParticipantsAvatarBlock } from "./ParticipantsAvatarBlock";

export interface RepliesBlockProps {
  message: any;
  chatRoom: any;
  isMinimal?: boolean;
}

export const RepliesBlock = ({
  message,
  chatRoom,
  isMinimal = false,
}: RepliesBlockProps) => {
  const { openThreadsRailbar } = useRailbar();
  const { replies_count, last_reply_at } = message;
  const { removeThreadIdUrlParam } = useThreads();

  return (
    <button
      type="button"
      className="hover:border-primary hover:bg-primary group -mt-2 ml-[72px] max-w-sm cursor-pointer rounded-md border border-transparent p-1 md:mr-0 hover:md:mr-[30px]"
      onClick={() => {
        removeThreadIdUrlParam();
        openThreadsRailbar(message, chatRoom);
      }}
    >
      <div
        className="flex items-center justify-between"
        data-testid="replies-block"
      >
        <div className="flex items-center space-x-2">
          <ParticipantsAvatarBlock
            avatars={message.thread_participants_preview}
          />
          <Typography.LabelSm weight="semibold">
            {replies_count === 1
              ? t("threads.one_reply")
              : t("threads.many_replies", { count: replies_count })}
          </Typography.LabelSm>
          {last_reply_at && (
            <div className="leading-none group-hover:hidden">
              <Typography.LabelXs color="text-light" weight="medium">
                {!isMinimal && t("last_reply")}{" "}
                {dateStringToTimeAgo(last_reply_at)}
              </Typography.LabelXs>
            </div>
          )}
          <div className="hidden leading-none group-hover:block">
            <Typography.LabelXs color="text-light" weight="medium">
              {t("messaging.view_thread")}
            </Typography.LabelXs>
          </div>
        </div>
        <div className="hidden group-hover:block">
          <Icon type="20-chevron-right" className="text-gray-400" />
        </div>
      </div>
    </button>
  );
};
