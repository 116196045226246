import { useMemo, useRef } from "react";
import classnames from "classnames";
import { truncate } from "lodash";
import timeAgo from "node-time-ago";
import { t } from "@/i18n-js/instance";
import type { Space } from "@/react/types/Space";
import { ShowPageLink } from "@circle-react/components/PostsV2/PostViewListItem/ShowPageLink";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { AuthorHeadline } from "./AuthorHeadline";
import { PostUserInfo } from "./PostUserInfo";
import { PostViewListItemWrapper } from "./PostViewListItemWrapper";

export interface PostMetaInfoProps {
  isRenderedOutsideTheSpace?: boolean;
  space?: Space;
  doesPostHaveCoverImage?: boolean;
  tagsLimit?: number;
  tagsShowMoreLabelVariant?: "words" | "numbers";
  wrapperClassName?: string;
  hideAuthorHeadline?: boolean;
}

/**
 * @deprecated
 * Please use the new PostMetaInfo component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostMetaInfo = ({
  isRenderedOutsideTheSpace,
  space,
  doesPostHaveCoverImage = false,
  tagsLimit,
  tagsShowMoreLabelVariant,
  wrapperClassName,
  hideAuthorHeadline,
}: PostMetaInfoProps) => {
  const { post, isSharedPostView } = useCurrentPostHelpers();
  const { community_member, hide_meta_info, member_tags } = post;
  const profileLinkProps = useProfileLinkProps({
    public_uid: community_member.public_uid,
    params: { show_back_link: true },
  });

  const visibleMemberTags = member_tags?.filter(
    (memberTag: any) =>
      memberTag?.is_public && memberTag?.display_locations?.post_bio,
  );

  const hasTags = useMemo(
    () => community_member.id && visibleMemberTags?.length > 0,
    [community_member.id, visibleMemberTags],
  );
  const isSmScreen = useSmScreenMediaQuery();

  const containerRef = useRef<HTMLDivElement>(null);
  const pageLinkRef = useRef<HTMLAnchorElement>(null);

  if (hide_meta_info || !space) {
    return null;
  }

  const truncatedHeadline = truncate(community_member.headline, {
    length: 160,
  });

  const headline = isRenderedOutsideTheSpace
    ? `${t("post.posted_in")} ${space.name}`
    : truncatedHeadline;

  const isAdmin = Boolean(community_member.admin);

  if (isSmScreen) {
    return (
      <PostViewListItemWrapper
        doesPostHaveCoverImage={doesPostHaveCoverImage}
        ref={containerRef}
      >
        <PostUserInfo post={post} />
        <button
          type="button"
          className="post__bio text-left"
          onClick={event => {
            if (!(event.target instanceof HTMLAnchorElement)) {
              pageLinkRef.current && pageLinkRef.current.click();
            }
          }}
        >
          <div
            className={classnames("author", {
              "no-headline": !community_member.headline && post.name,
              "has-tags": hasTags,
            })}
          >
            <div className="author__name">
              <a {...profileLinkProps} className="!text-dark">
                {community_member.name}
              </a>
            </div>
            {!isSharedPostView && (hasTags || isAdmin) && (
              <div className="author__tags member-tags !m-0">
                <MemberTags
                  memberTags={visibleMemberTags}
                  isAdmin={isAdmin}
                  limit={tagsLimit}
                  showMoreLabelVariant={tagsShowMoreLabelVariant}
                />
              </div>
            )}
          </div>
          {!hideAuthorHeadline && (
            <AuthorHeadline headline={headline} post={post} ref={pageLinkRef} />
          )}
        </button>
      </PostViewListItemWrapper>
    );
  }

  return (
    <PostViewListItemWrapper
      doesPostHaveCoverImage={doesPostHaveCoverImage}
      ref={containerRef}
      className={wrapperClassName}
    >
      <PostUserInfo post={post} />
      <button
        type="button"
        className="post__bio text-left"
        onClick={event => {
          if (!(event.target instanceof HTMLAnchorElement)) {
            pageLinkRef.current && pageLinkRef.current.click();
          }
        }}
      >
        <div
          className={classnames("author", {
            "no-headline": !community_member.headline && post.name,
            "has-tags": hasTags,
          })}
        >
          <div className="author__name">
            <a {...profileLinkProps} className="!text-dark">
              {community_member.name}
            </a>
          </div>
          {!isSharedPostView && (hasTags || isAdmin) && (
            <div className="author__tags member-tags">
              <MemberTags
                memberTags={visibleMemberTags}
                isAdmin={isAdmin}
                limit={tagsLimit}
                showMoreLabelVariant={tagsShowMoreLabelVariant}
              />
            </div>
          )}
          <div className="author__time">
            <ShowPageLink
              post={post}
              className="ago !text-dark"
              forwardedRef={pageLinkRef}
            >
              {timeAgo(post.created_at)}
            </ShowPageLink>
          </div>
        </div>
        {!hideAuthorHeadline && (
          <AuthorHeadline headline={headline} post={post} />
        )}
      </button>
    </PostViewListItemWrapper>
  );
};
