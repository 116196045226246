import { forwardRef } from "react";
import classnames from "classnames";
import timeAgo from "node-time-ago";
import { t } from "@/i18n-js/instance";
import type { Post } from "@/react/types/Post";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { ShowPageLink } from "./ShowPageLink";

export interface AuthorHeadlineProps {
  headline: string;
  post: Post;
}

/**
 * @deprecated
 * Please use the new AuthorHeadline component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const AuthorHeadline = forwardRef<
  HTMLAnchorElement,
  AuthorHeadlineProps
>(({ headline, post }, pageLinkRef) => {
  const { community_member, name, flagged_for_approval_at, created_at } = post;
  const isSmScreen = useSmScreenMediaQuery();

  if (isSmScreen)
    return (
      <div className="post__meta">
        {!name && flagged_for_approval_at ? (
          <span className="post__headline-tag !ml-0">{t("in_review")}</span>
        ) : (
          community_member &&
          headline && (
            <>
              <span
                className={classnames("author__credentials !text-dark", {
                  "is-admin": !!community_member.is_admin,
                })}
              >
                {headline}
              </span>
              <span className="bg-badge mx-2 inline-block h-[0.15rem] w-[0.15rem] rounded-full align-middle" />
            </>
          )
        )}

        <div className="author__time inline-block">
          <ShowPageLink
            post={post}
            className="ago !text-dark"
            forwardedRef={pageLinkRef}
          >
            {timeAgo(created_at)}
          </ShowPageLink>
        </div>
      </div>
    );

  return (
    <div className="post__meta">
      {!name && flagged_for_approval_at ? (
        <span className="post__headline-tag !ml-0">{t("in_review")}</span>
      ) : (
        community_member && (
          <span
            className={classnames("author__credentials !text-dark", {
              "is-admin": !!community_member.is_admin,
            })}
          >
            {headline}
          </span>
        )
      )}
    </div>
  );
});

AuthorHeadline.displayName = "AuthorHeadline";
