import { useRef } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { CommentsList } from "../CommentsList";
import { ActionMenu } from "./ActionMenu";
import { Content } from "./Content";
import { useEditModal } from "./EditModal";
import { EngagementActions } from "./EngagementActions";
import { useLikesModal } from "./LikesModal";
import { ReportModal } from "./ReportModal";
import { useDeleteHandler } from "./useDeleteHandler";
import { useLikeHandler } from "./useLikeHandler";
import { useReplyHandler } from "./useReplyHandler";
import { useReportHandler } from "./useReportHandler";

/**
 * @deprecated
 * Please use the new Comment component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const Comment = ({
  space,
  post,
  comment,
  showReplyForm = false,
  autofocusReplyForm = false,
  onReplyClick,
  onUpdate,
  onDelete,
  onReplyCreate,
  onReplyDelete,
}) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const {
    id,
    parent_comment_id: parentCommentId,
    community_member: communityMember,
    replies,
    replies_count: repliesCount,
    flagged_for_approval_at: flaggedForApprovalAt,
    policies,
  } = comment;
  const isFlagged = !!flaggedForApprovalAt;
  const isReply = !!parentCommentId;
  const canReport = policies.can_report && !isFlagged;
  const canReview = policies.can_manage && isFlagged;
  const canEdit = policies.can_edit;
  const canDestroy = policies.can_destroy;
  const canBookmark = policies.can_bookmark;

  const replyFormRef = useRef();

  const {
    openReplyForm,
    shouldShowReplyForm,
    communityMemberForReply,
    canReply,
  } = useReplyHandler({ comment, showReplyForm, replyFormRef });

  const {
    toggleLike: handleLikeClick,
    isLiked,
    likesCount,
    canLike,
    canSeeLikes,
    isLoading: loadingLikeChange,
  } = useLikeHandler({ comment });

  const likesModal = useLikesModal();
  const openLikesModal = () =>
    likesModal.show({ id, placeholderLikesCount: likesCount });

  const editModal = useEditModal();
  const openEditModal = () => editModal.show({ space, comment, onUpdate });

  const { isReportModalOpen, openReportModal, closeReportModal, submitReport } =
    useReportHandler({ comment });

  const {
    isDeleteConfirmModalOpen,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
    deleteComment,
    isDeletingComment,
  } = useDeleteHandler({
    comment,
    onDelete,
  });

  const handleReplyClickFunc = onReplyClick || openReplyForm;
  const handleReplyClick = () => handleReplyClickFunc({ communityMember });

  return (
    <div className="post--comment" id={`comment_wrapper_${id}`}>
      <div className="text-dark">
        <div className="post__content">
          <Content post={post} comment={comment} />

          <EngagementActions
            canReply={canReply}
            canLike={canLike}
            canSeeLikes={canSeeLikes}
            isLiked={isLiked}
            likesCount={likesCount}
            isLikeDisabled={loadingLikeChange}
            onReplyClick={isViewOnlyMasquerading ? noop : handleReplyClick}
            onLikeClick={isViewOnlyMasquerading ? noop : handleLikeClick}
            onLikesCountClick={isViewOnlyMasquerading ? noop : openLikesModal}
          />
          <ActionMenu
            comment={comment}
            canReport={canReport}
            canReview={canReview}
            canEdit={canEdit}
            canDestroy={canDestroy}
            canBookmark={canBookmark}
            isReply={isReply}
            onReportClick={isViewOnlyMasquerading ? noop : openReportModal}
            onEditClick={isViewOnlyMasquerading ? noop : openEditModal}
            onDeleteClick={
              isViewOnlyMasquerading ? noop : openDeleteConfirmModal
            }
            onUpdate={onUpdate}
          />
          {canReport && (
            <ReportModal
              show={isReportModalOpen}
              onClose={closeReportModal}
              onReportSubmit={submitReport}
            />
          )}
          {canDestroy && (
            <ConfirmationModal
              isOpen={isDeleteConfirmModalOpen}
              onClose={closeDeleteConfirmModal}
              onConfirm={deleteComment}
              confirmText={isDeletingComment ? t("deleting") : t("delete")}
              confirmVariant="danger"
              title={t("comments.delete.confirm.title")}
              disabled={isDeletingComment}
            >
              {t("comments.delete.confirm.description")}
            </ConfirmationModal>
          )}
        </div>
      </div>
      {!isReply && (
        <div className="post__replies">
          <CommentsList
            space={space}
            post={post}
            parentCommentId={id}
            preloaded
            preloadedComments={replies}
            preloadedCommentsTotalCount={repliesCount}
            showReplyForm={shouldShowReplyForm}
            autofocusReplyForm={autofocusReplyForm}
            communityMemberForReply={communityMemberForReply}
            onCommentReplyClick={openReplyForm}
            onCommentCreate={onReplyCreate}
            onCommentDelete={onReplyDelete}
            formRef={replyFormRef}
          />
        </div>
      )}
    </div>
  );
};

Comment.propTypes = {
  space: PropTypes.object,
  post: PropTypes.object.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    parent_comment_id: PropTypes.number,
    community_member: PropTypes.object.isRequired,
    is_replying_enabled: PropTypes.bool.isRequired,
    is_liking_enabled: PropTypes.bool.isRequired,
    replies_count: PropTypes.number,
    replies: PropTypes.arrayOf(PropTypes.object),
    is_liked: PropTypes.bool.isRequired,
    likes_count: PropTypes.number.isRequired,
    flagged_for_approval_at: PropTypes.string,
    policies: PropTypes.shape({
      can_report: PropTypes.bool.isRequired,
      can_manage: PropTypes.bool.isRequired,
      can_edit: PropTypes.bool.isRequired,
      can_destroy: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  showReplyForm: PropTypes.bool,
  autofocusReplyForm: PropTypes.bool,
  onReplyClick: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReplyCreate: PropTypes.func.isRequired,
  onReplyDelete: PropTypes.func.isRequired,
};
