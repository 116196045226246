import React, { useState } from "react";
import * as HoverCard from "@radix-ui/react-hover-card";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { CardContent } from "./CardContent";

export interface ProfileHoverCardProps {
  children: React.ReactNode;
  communityMemberId: number | string;
  publicUid?: string;
  avatarURL?: string;
  name?: string;
  headline?: string;
  messagingEnabled?: boolean;
  disabled?: boolean;
}

export const ProfileHoverCard = ({
  children,
  communityMemberId,
  publicUid,
  messagingEnabled,
  avatarURL,
  name = "",
  headline = "",
  disabled = false,
}: ProfileHoverCardProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isV3Enabled } = useIsV3();

  return (
    <HoverCard.Root
      openDelay={300}
      closeDelay={100}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <HoverCard.Trigger asChild>
        <div>{children}</div>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        {isV3Enabled && !disabled && (
          <CardContent
            communityMemberId={communityMemberId}
            publicUid={publicUid}
            avatarURL={avatarURL}
            name={name}
            headline={headline}
            messagingEnabled={messagingEnabled}
            closeCard={() => setIsOpen(false)}
          />
        )}
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
