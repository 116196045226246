import { t } from "@/i18n-js/instance";
import { Input } from "@/react/components/FormV2/Input";
import {
  isPrivateSpace,
  isSecretSpace,
} from "@circle-react/helpers/spaceHelpers";
import type { Event } from "@circle-react/types/Event";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { ToggleSwitchWithLabel } from "@circle-react-shared/uikit/ToggleSwitchWithLabel";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { usePostShareOptions } from "./usePostShareOptions";

interface ShareDropdownProps {
  getSharePostLink: () => void;
  post: Event;
  sharePostData: {
    share_post_link: string;
  };
}

export const ShareDropdown = ({
  getSharePostLink,
  post,
  sharePostData,
}: ShareDropdownProps) => {
  const {
    onClickCopyLink,
    onChange,
    formData,
    currentCommunity,
    currentSpace,
  } = usePostShareOptions({
    post,
    getSharePostLink,
    sharePostData,
  });

  const showWarningMessage =
    isSecretSpace(currentSpace) ||
    isPrivateSpace(currentSpace) ||
    currentCommunity?.is_private;

  const warningMessage = () => {
    if (currentCommunity?.is_private && currentSpace?.visibility == "open") {
      return t("post.share.share_to_web_open_content_warning");
    }
    return isSecretSpace(currentSpace)
      ? t("post.share.share_to_web_secret_content_warning")
      : t("post.share.share_to_web_private_content_warning");
  };

  const renderPublicCommunityPublicSpaceContent = () => (
    <div className="flex flex-row items-center px-4 py-2">
      <div className="flex flex-1 flex-col">
        <Typography.LabelMd weight="bold">
          {t("post.share.share_to_web")}
        </Typography.LabelMd>
        <Typography.LabelSm>
          {t("post.share.post_is_public")}
        </Typography.LabelSm>
      </div>
      <div>
        <Button
          type="button"
          variant="secondary"
          onClick={() => onClickCopyLink(null)}
        >
          {t("copy_link")}
        </Button>
      </div>
    </div>
  );

  const renderPublicCommunityPrivateSpaceContent = () => (
    <div>
      <Dropdown.ItemLink
        onClick={() => {
          onChange({
            share_to_web: !formData.post.share_to_web,
          });
        }}
      >
        <ToggleSwitchWithLabel
          label={t("post.share.share_to_web")}
          tooltipText={t("post.share.share_to_web_on_description")}
          field="share_to_web"
          value={formData.post.share_to_web}
          variant="small"
        />
      </Dropdown.ItemLink>
      {formData.post.share_to_web && (
        <>
          <div className="flex flex-row items-center">
            <div className="mb-2 mt-1 flex-1 px-5">
              <Input
                disabled
                value={sharePostData?.share_post_link}
                rightAddon={
                  <button
                    type="button"
                    onClick={() =>
                      onClickCopyLink(sharePostData?.share_post_link)
                    }
                  >
                    <Icon type="20-copy" />
                  </button>
                }
              />
            </div>
          </div>
          {showWarningMessage && (
            <div className="flex flex-row items-center">
              <div className="mb-2 mt-1 flex-1 px-5">
                <Typography.LabelSm>{warningMessage()}</Typography.LabelSm>
              </div>
            </div>
          )}
          {!currentCommunity?.is_private && (
            <Dropdown.ItemLink
              onClick={() =>
                onChange({
                  minimal_layout: !formData.post.minimal_layout,
                })
              }
            >
              <ToggleSwitchWithLabel
                label={t("post.share.minimal_layout")}
                tooltipText={t("post.share.minimal_layout_description")}
                field="minimal_layout"
                value={formData.post.minimal_layout}
                variant="small"
              />
            </Dropdown.ItemLink>
          )}
        </>
      )}
    </div>
  );

  const renderContent = () => {
    if (!currentCommunity.is_private && !currentSpace?.is_private) {
      return renderPublicCommunityPublicSpaceContent();
    }

    return renderPublicCommunityPrivateSpaceContent();
  };

  return renderContent();
};
