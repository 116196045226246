import PropTypes from "prop-types";
import I18n from "@/i18n-js/instance";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Comment } from "../Comment";
import { CommentForm } from "../CommentForm";
import { useJumpToComment } from "../useJumpToComment";
import { useCommentsList } from "./useCommentsList";

/**
 * @deprecated
 * Please use the new CommentsList component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentsList = ({
  space,
  post,
  parentCommentId,
  preloaded = false,
  preloadedComments = [],
  preloadedCommentsTotalCount = 0,
  showReplyForm = false,
  autofocusReplyForm = false,
  communityMemberForReply,
  onCommentReplyClick,
  onCommentCreate,
  onCommentDelete,
  formRef,
}) => {
  const currentCommunityMember = useCurrentCommunityMember();

  const {
    comments,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    addCommentToList,
    updateCommentInList,
    removeCommentFromList,
  } = useCommentsList({
    postId: post.id,
    parentCommentId,
    preloaded,
    preloadedComments,
    preloadedCommentsTotalCount,
    postCommentsClosed: post.is_comments_closed,
  });

  useJumpToComment({ enabled: !isLoading && !parentCommentId });

  const handleCommentCreate = comment => {
    addCommentToList(comment);
    onCommentCreate(comment);
  };

  const handleCommentDelete = comment => {
    removeCommentFromList(comment);
    onCommentDelete(comment);
  };

  const isLoadingComments = isLoading || isFetchingNextPage;

  const shouldShowForm =
    currentCommunityMember &&
    space &&
    showReplyForm &&
    (!isLoadingComments || comments.length > 0);

  return (
    <>
      {comments.map(comment => (
        <Comment
          key={comment.id}
          space={space}
          post={post}
          comment={comment}
          autofocusReplyForm
          onReplyClick={onCommentReplyClick}
          onUpdate={updateCommentInList}
          onDelete={handleCommentDelete}
          onReplyCreate={onCommentCreate}
          onReplyDelete={onCommentDelete}
        />
      ))}
      {isLoadingComments && <LoaderRow />}
      {!isLoadingComments && hasNextPage && (
        <div className="load-more">
          <button
            type="button"
            onClick={event => {
              event.preventDefault();
              fetchNextPage();
            }}
            className={
              parentCommentId
                ? "load-more__replies text-link"
                : "btn btn-secondary load-more__comments"
            }
          >
            {I18n.t(
              parentCommentId
                ? "comments.list.show_more_replies"
                : "comments.list.show_more_comments",
            )}
          </button>
        </div>
      )}
      {shouldShowForm && (
        <CommentForm
          spaceId={space.id}
          spaceIsPrivate={space.is_private}
          postId={post.id}
          parentCommentId={parentCommentId}
          communityMemberForReply={communityMemberForReply}
          autoFocus={autofocusReplyForm}
          onCreate={handleCommentCreate}
          bodyInputRef={formRef}
        />
      )}
    </>
  );
};

CommentsList.propTypes = {
  space: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_private: PropTypes.bool.isRequired,
  }),
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_comments_closed: PropTypes.bool.isRequired,
  }).isRequired,
  parentCommentId: PropTypes.number,
  preloaded: PropTypes.bool,
  preloadedComments: PropTypes.arrayOf(PropTypes.object),
  preloadedCommentsTotalCount: PropTypes.number,
  showReplyForm: PropTypes.bool,
  autofocusReplyForm: PropTypes.bool,
  communityMemberForReply: PropTypes.object,
  onCommentReplyClick: PropTypes.func,
  onCommentCreate: PropTypes.func.isRequired,
  onCommentDelete: PropTypes.func.isRequired,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};
