import { useMemo, useState } from "react";
import { PropTypes } from "prop-types";
import { t } from "@/i18n-js/instance";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { RadioButtonGroup } from "@circle-react-shared/RadioButtonGroup";
import { RECURRING_EDIT_MODE } from "../../utils";

const translationRoot = "events.edit.recurring_event_modal";

export const EditRecurringModal = ({
  show = false,
  onConfirm,
  onCloseModal,
  isPublishing = false,
  showCurrentOptionOnly,
  shouldHideAllEventOption,
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState();

  const onRadioChangeHandler = value => {
    setSelectedFrequency(value);
  };

  const onConfirmHandler = () => {
    onConfirm(selectedFrequency);
  };

  const defaultOptions = [
    {
      label: t(`${translationRoot}.this_event`),
      value: RECURRING_EDIT_MODE.CURRENT,
    },
    {
      label: t(`${translationRoot}.this_and_following`),
      value: RECURRING_EDIT_MODE.REMAINING,
    },
    {
      label: t(`${translationRoot}.all_events`),
      value: RECURRING_EDIT_MODE.ALL,
    },
  ];

  const futureOptionsOnly = defaultOptions.filter(
    option => option.value !== RECURRING_EDIT_MODE.ALL,
  );

  const currentOption = defaultOptions.filter(
    option => option.value === RECURRING_EDIT_MODE.CURRENT,
  );

  const menuOptions = useMemo(() => {
    let options = defaultOptions;

    if (showCurrentOptionOnly) {
      options = currentOption;
    } else if (shouldHideAllEventOption) {
      options = futureOptionsOnly;
    }

    return options;
  }, [showCurrentOptionOnly, shouldHideAllEventOption]);

  return (
    <ConfirmationModal
      title={
        isPublishing
          ? t(`${translationRoot}.title_publish`)
          : t(`${translationRoot}.title`)
      }
      isOpen={show}
      confirmText={t(`${translationRoot}.accept`)}
      cancelText={t(`${translationRoot}.cancel`)}
      onClose={onCloseModal}
      onCancel={onCloseModal}
      onConfirm={onConfirmHandler}
      size="sm"
    >
      <div className="mt-6">
        <RadioButtonGroup
          defaultChecked={RECURRING_EDIT_MODE.CURRENT}
          name="recurring_event_settings.frequency_edit"
          options={menuOptions}
          size="sm"
          onChange={onRadioChangeHandler}
        />
      </div>
    </ConfirmationModal>
  );
};

EditRecurringModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCloseModal: PropTypes.func,
  titleText: PropTypes.string,
  isPublishing: PropTypes.bool,
  showCurrentOptionOnly: PropTypes.bool,
  shouldHideAllEventOption: PropTypes.bool,
};
