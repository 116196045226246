import { utcToZonedTime } from "date-fns-tz";
import { upperFirst } from "lodash";
import { getCurrentBcp47Locale } from "@circle-react/helpers/localeHelpers";

export const formatDateString = (
  dateString: string,
  options?: Intl.DateTimeFormatOptions,
) => {
  try {
    if (!dateString) {
      throw new Error("Missing date string");
    }
    const locale = getCurrentBcp47Locale();
    const formatter = new Intl.DateTimeFormat(locale, options);
    return upperFirst(formatter.format(new Date(dateString)));
  } catch (error) {
    if (error instanceof Error) {
      console.error(`Could not parse date: ${error.message}`);
    }
    return "";
  }
};

export const dateToTimeString = (dateString: string, timeZone: string) =>
  formatDateString(dateString, {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    timeZone: timeZone,
  });

export const dateLongMonthYearString = (dateString: string, timeZone: string) =>
  formatDateString(dateString, { month: "long", year: "numeric", timeZone });

export const getDateInTimeZoneWithTime = (
  localDate: Date,
  timeZone: string = "UTC",
) => {
  // Get the current time
  const now = new Date();

  // Extract hours, minutes, and seconds from the current time
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentSeconds = now.getSeconds();

  // Update the local date with the current time
  localDate.setHours(currentHours);
  localDate.setMinutes(currentMinutes);
  localDate.setSeconds(currentSeconds);

  return utcToZonedTime(localDate.toISOString(), timeZone);
};
