import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { toLocaleString } from "@circle-react/helpers/number";
import { Icon } from "@circle-react-shared/Icon";

interface LikeButtonProps {
  name: string | null;
  onLikeToggle: () => void;
  is_liked_by_current_user: boolean;
  user_likes_count: number;
  isOrCanBeCommunityMember: boolean;
  className?: string;
  likedSpanClassName?: string;
  showCount?: boolean;
  iconClassName: "!text-default" | "!text-dark";
}

/**
 * @deprecated
 * Please use the new LikeButton component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const LikeButton = ({
  name,
  onLikeToggle,
  is_liked_by_current_user,
  user_likes_count,
  isOrCanBeCommunityMember,
  className,
  iconClassName = "!text-default",
  likedSpanClassName,
  showCount = true,
}: LikeButtonProps) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  return (
    <div className={classnames("col-span-1", className)}>
      <button
        type="button"
        onClick={onLikeToggle}
        aria-label={t("like_post_aria_label", {
          name: name ?? "",
        })}
        className={classnames(
          "action-item action-link action-like post__actions--like text-default !flex !items-center !justify-center !text-center",
          {
            liked: is_liked_by_current_user,
            disabled: !isOrCanBeCommunityMember || isViewOnlyMasquerading,
          },
        )}
      >
        <span
          className={classnames(
            "action__icon !inline-flex align-text-top",
            is_liked_by_current_user && likedSpanClassName,
          )}
        >
          <Icon
            type="like"
            className={classnames({
              [iconClassName]: !is_liked_by_current_user,
              "!text-brand": is_liked_by_current_user,
            })}
            useWithFillCurrentColor={!is_liked_by_current_user}
            aria-hidden
          />
        </span>
        {showCount && (
          <span
            className={classnames("count", {
              "!text-dark": !is_liked_by_current_user,
            })}
          >
            {toLocaleString(user_likes_count)}
          </span>
        )}
      </button>
    </div>
  );
};
