import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useChatRoomStore } from "@circle-react/hooks/chatsV2/store";
import { useChatsV2Api } from "@circle-react/hooks/chatsV2/useChatsV2Api";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { UnreadMessageLine } from "../../ChatThreads/UnreadMessageLine";
import {
  doesActiveMessageIdMatches,
  shouldHightlightThreadMessage,
} from "../helpers";
import { useDeleteMessageItemModal } from "./DeleteMessageItemModal";
import { MessageActions } from "./MessageActions";
import { MessageBlock } from "./MessageBlock";
import { useMessageActions } from "./hooks";

export interface MessageItemProps {
  chatRoom: any;
  chatThreadsEnabled?: boolean;
  currentParticipant: any;
  isInitialPage?: boolean;
  isParentMessage?: boolean;
  isRenderedOnHeader?: boolean;
  isUnreadMessageLineEnabled?: boolean;
  lastReadMessageId?: number;
  message: any;
  onBookmarkToggle?: (messageId: any) => void;
  participants?: any[];
  roles?: any;
  setUnreadMessageFlow?: (value: boolean) => void;
  isLiveStream?: boolean;
  chatRoomUrl?: string;
}

export const MessageItem = ({
  chatRoom,
  chatThreadsEnabled = false,
  currentParticipant,
  isInitialPage = false,
  isParentMessage = false,
  isRenderedOnHeader = false,
  isUnreadMessageLineEnabled = false,
  lastReadMessageId,
  message,
  onBookmarkToggle,
  participants,
  roles = {},
  setUnreadMessageFlow,
  isLiveStream = false,
  chatRoomUrl,
}: MessageItemProps) => {
  const { updateRichTextMessage }: any = useChatsV2Api();
  const { messageHighlightId }: any = useRailbar();
  const { pathname }: any = useLocation();
  const isThreadsPage = pathname === "/threads";
  const { activeMessageId } = useChatRoomStore();

  const [editMessageId, setEditMessageId] = useState(null);
  const { id, replies_count } = message;

  const isEditMessage = id ? editMessageId === id : false;

  const { canUserModifyMessage } = useMessageActions({
    message,
    chatRoom,
  });

  const shouldShowMessageActions = !!currentParticipant;

  const handleEsc = () => setEditMessageId(null);

  useHotkeys(
    "esc",
    handleEsc,
    {
      enabled: isEditMessage,
      enableOnContentEditable: true,
    },
    [isEditMessage],
  );

  const handleSubmit = ({ body, attachments = [] }: any) => {
    updateRichTextMessage({
      chatRoomUuid: chatRoom.uuid,
      messageId: id,
      rich_text_body: {
        body,
        attachments,
      },
    });
    handleEsc();
  };

  const deleteMessageItemModal = useDeleteMessageItemModal();

  const toggleDeleteConfirmationModal = () => {
    if (deleteMessageItemModal.visible) {
      void deleteMessageItemModal.hide();
    } else {
      void deleteMessageItemModal.show({
        chatRoom,
        currentParticipant,
        handleSubmit,
        isEditMessage,
        isInitialPage,
        message,
        messageId: id,
        participants,
        repliesCount: replies_count,
      });
    }
  };

  const shouldShowUnreadLine =
    isUnreadMessageLineEnabled && lastReadMessageId === message.id;

  const shouldActiveMessageIdMatches = doesActiveMessageIdMatches({
    activeMessageId,
    id,
    isMessageThread: true,
  });
  return (
    <div>
      <div
        id={`thread-${id}`}
        className={classNames(
          "group/action bg-primary relative flex w-full flex-col",
          {
            relative: !isParentMessage || isThreadsPage,
            "hover:bg-secondary": !activeMessageId && shouldShowMessageActions,
            "!bg-secondary": isEditMessage || shouldActiveMessageIdMatches,
            "message-highlight": shouldHightlightThreadMessage({
              id,
              messageHighlightId,
              isParentMessage,
            }),
          },
        )}
        data-testid="message-item"
      >
        <MessageBlock
          isModal={false}
          handleSubmit={handleSubmit}
          currentParticipant={currentParticipant}
          message={message}
          isInitialPage={isInitialPage}
          isEditMessage={isEditMessage}
          toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
          isPinned={false}
          roles={roles}
          isParentMessage={isParentMessage}
          chatRoom={chatRoom}
          chatRoomUrl={chatRoomUrl}
          participants={participants}
          isMessageThread
          isRenderedOnHeader={isRenderedOnHeader}
          messageBodyClassName="mr-4"
        />
        {shouldShowMessageActions && (
          <MessageActions
            isPinned={false}
            setEditMessageId={setEditMessageId}
            chatRoom={chatRoom}
            toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
            message={message}
            currentParticipant={currentParticipant}
            canUserModifyMessage={canUserModifyMessage}
            chatThreadsEnabled={chatThreadsEnabled}
            isParentMessage={isParentMessage}
            isRenderedOnHeader={isRenderedOnHeader}
            isMessageThread
            onBookmarkToggle={onBookmarkToggle}
            setUnreadMessageFlow={setUnreadMessageFlow}
            chatRoomUrl={chatRoomUrl}
            isLiveStream={isLiveStream}
          />
        )}
      </div>
      {shouldShowUnreadLine && <UnreadMessageLine />}
    </div>
  );
};

MessageItem.displayName = "MessageItem";
