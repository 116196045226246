import { useEffect, useState } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import CommentsV3 from "@circle-react/components/CommentsV3";
import { useCommentFromLocationHash } from "@circle-react/components/CommentsV3/useCommentFromLocationHash";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import {
  useCurrentPostContext,
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { toLocaleString } from "@circle-react/helpers/number";
import { isEventPost } from "@circle-react/helpers/postHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { CommentsClosedBanner } from "./CommentsClosedBanner";
import { GatedComments } from "./GatedComments";
import { LeaveCommentButton } from "./LeaveCommentButton";
import { usePostModalCommentFormDynamic } from "./usePostModalCommentFormDynamic";

export const CommentsSection = ({
  autofocusReplyForm,
  commentsFormRef,
  className = "",
}) => {
  const {
    post,
    showGatedCommentSectionAtBottom,
    showGatedCommentSectionAtTop,
    shouldShowLeaveCommentButton,
    shouldHideCommentsListForSpace,
    refetchPostDetails,
    updatePostDetailsQueryCache,
  } = useCurrentPostHelpers();
  const { commentId } = useCommentFromLocationHash();
  const [linkedCommentId, setLinkedCommentId] = useState(commentId);
  const [showCommentHierarchy, setShowCommentHierarchy] = useState(
    commentId && post.comments_count > 4,
  );
  const { data: space } = useCurrentSpaceContext();
  const { currentCommunityMember } = usePunditUserContext();
  const { isUsedInMinimalTipTapViewModal } = useCurrentPostContext();
  const { is_comments_closed: isCommentsClosed } = post;
  const onChange = () => {
    refetchPostDetails && refetchPostDetails();
  };

  const incrementCommentsCount = () => {
    updatePostDetailsQueryCache &&
      updatePostDetailsQueryCache({
        comments_count: post.comments_count + 1,
      });
  };
  const navigateToAllComments = () => {
    setShowCommentHierarchy(false);
    setLinkedCommentId(null);

    // TODO: Handle in a react/router way
    window.location.hash = "#all_comments";
  };

  const triggerOnChangeAndNavigateToAllComments = () => {
    onChange();
    navigateToAllComments();
  };

  const handleShowAllCommentsClick = event => {
    event.preventDefault();
    navigateToAllComments();
  };

  const shouldHideCommentsList = shouldHideCommentsListForSpace({ space });

  useEffect(() => {
    setLinkedCommentId(commentId);
  }, [commentId]);

  const { commentsSectionRef, dynamicMarginBottom } =
    usePostModalCommentFormDynamic();

  const shouldShowGatedComments = !(
    shouldShowLeaveCommentButton || isEventPost(post)
  );

  return (
    <div
      ref={commentsSectionRef}
      className={classNames(
        "post__comments trix-v2",
        {
          "transition-[margin] duration-150 ease-in-out":
            isUsedInMinimalTipTapViewModal && !isCommentsClosed,
        },
        className,
      )}
      style={{
        marginBottom:
          isUsedInMinimalTipTapViewModal && !isCommentsClosed
            ? `${dynamicMarginBottom}px`
            : undefined,
      }}
      data-controller="comments"
      id="all_comments"
      data-post-id={post.id}
    >
      {isCommentsClosed && <CommentsClosedBanner className="m-4" />}
      {shouldShowLeaveCommentButton && <LeaveCommentButton post={post} />}
      {shouldShowGatedComments && showGatedCommentSectionAtTop && (
        <GatedComments post={post} space={space} />
      )}
      {!shouldHideCommentsList && showCommentHierarchy && (
        <>
          <div className="show-all-comments__wrapper px-2.5 py-4 md:p-6">
            <a
              className="show-all-comments__link focus-visible:outline-secondary border-hover bg-primary text-dark hover:text-darkest focus-visible:text-darkest inline-flex items-center gap-2 rounded-md border px-4 py-2 text-sm font-semibold leading-normal transition-colors duration-150 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              href="#all_comments"
              onClick={handleShowAllCommentsClick}
            >
              <Icon type="arrow-solid-top-left" />
              {t("post.comments_section.show_all_count_comments", {
                count: post.comments_count,
                formatted_count: toLocaleString(post.comments_count),
              })}
            </a>
          </div>
          <CommentsV3.CommentHierarchy
            communityMember={currentCommunityMember}
            space={space}
            post={post}
            id={linkedCommentId}
            showReplyForm={!isCommentsClosed}
            autofocusReplyForm={autofocusReplyForm}
            onCommentDelete={triggerOnChangeAndNavigateToAllComments}
            onReplyCreate={incrementCommentsCount}
            onReplyDelete={onChange}
          />
        </>
      )}
      {!shouldHideCommentsList && !showCommentHierarchy && (
        <CommentsV3.CommentsList
          communityMember={currentCommunityMember}
          space={space}
          post={post}
          showReplyForm={!isCommentsClosed}
          autofocusReplyForm={autofocusReplyForm}
          formRef={commentsFormRef}
          onCommentCreate={incrementCommentsCount}
          onCommentDelete={onChange}
        />
      )}
      {shouldShowGatedComments && showGatedCommentSectionAtBottom && (
        <GatedComments post={post} space={space} />
      )}
    </div>
  );
};
