import type { ReactNode } from "react";
import { t } from "@/i18n-js/instance";
import { useShowProfileEdit } from "@circle-react/hooks/profile/useShowProfileEdit";
import type { Person } from "@circle-react/types/AudienceList";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface NameCellProps {
  member: Person;
  isAvatarHidden?: boolean;
  children: ReactNode;
}

export const NameCell = ({
  member,
  isAvatarHidden = false,
  children,
}: Readonly<NameCellProps>) => {
  const { showMemberProfileEdit } = useShowProfileEdit();
  const { name, avatar_url } = member;

  if (!name) {
    return <>{t("dash")}</>;
  }

  return (
    <div className="group flex items-center justify-between gap-x-1">
      <button
        className="flex items-center gap-3 text-left"
        type="button"
        onClick={() => showMemberProfileEdit({ member })}
      >
        {!isAvatarHidden && <UserImage src={avatar_url} name={name} size="6" />}
        <Typography.LabelSm weight="medium">{name}</Typography.LabelSm>
      </button>
      {children}
    </div>
  );
};
