import { TiptapCommentBox } from "@circle-react/components/CommentsV2/CommentForm/TiptapCommentBox";
import {
  useCurrentCommunityMember,
  useCurrentPostContext,
} from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Form } from "@circle-react-uikit/Form";
import { useCommentForm } from "./useCommentForm";

/**
 * @deprecated
 * Please use the new CommentForm component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentForm = ({
  spaceId,
  postId,
  parentCommentId,
  communityMemberForReply,
  autoFocus = false,
  onCreate,
  bodyInputRef,
}: any) => {
  const currentCommunityMember = useCurrentCommunityMember();
  const { usedIn } = useCurrentPostContext();

  const shouldShowUserImage = usedIn !== "full-post-modal";

  const { createComment } = useCommentForm({
    postId,
    parentCommentId,
    onCreate,
  });

  return (
    <div
      className={classNames("post__reply items-start gap-3", {
        "reply-to-parent": parentCommentId,
        "reply-to-post": !parentCommentId,
      })}
    >
      {shouldShowUserImage && (
        <UserImage
          src={currentCommunityMember.avatar_url}
          name={currentCommunityMember.name}
          size="10"
        />
      )}
      <Form
        className="relative w-full"
        onSubmit={(formData: any) => createComment(formData)}
      >
        <TiptapCommentBox
          spaceId={spaceId}
          postId={postId}
          communityMemberForReply={communityMemberForReply}
          autoFocus={autoFocus}
          editorRef={bodyInputRef}
          isEdit={false}
          parentCommentId={parentCommentId}
        />
      </Form>
    </div>
  );
};
