import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useSubmitViaKeyboard } from "@circle-react/hooks/utils/useSubmitViaKeyboard";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { RichTextInput } from "@circle-react-uikit/RichTextInput";

/**
 * @deprecated
 * Please use the new Content component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const Content = ({ spaceId, spaceIsPrivate, onClose }) => {
  const {
    watch,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = useFormContext();
  const { body } = watch();
  const isLoading = isSubmitting || isSubmitSuccessful;
  const submitEnabled = !!body && !isLoading;

  useSubmitViaKeyboard({ enabled: submitEnabled });

  const onCloseWithConfirm = () => {
    if (!isDirty || confirm(t("prevent_accidental_leave_message"))) {
      onClose();
    }
  };

  // `overflow-visible` ensures that toolbars, e.g. the emoji picker, aren't getting cut off.
  // `transform-none` disables in/out transitions to fix issues with Trix toolbar positioning.
  return (
    <Modal.Content
      size="lg"
      wrapperClassName="!h-full"
      className="!transform-none !overflow-visible"
    >
      <Modal.Header>
        <Modal.Title>{t("comments.edit.form.title")}</Modal.Title>
        <Modal.HeaderActions>
          <Modal.CloseButton onClick={onCloseWithConfirm} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body className="comment__edit-modal-content-body trix-v2 max-h-[calc(100dvh-82px-65px)] !overflow-visible md:max-h-none">
        <RichTextInput
          id="comment-edit-body"
          name="body"
          placeholder={t("comments.form.fields.body_placeholder")}
          space_id={spaceId}
          is_private_space={spaceIsPrivate}
        />
      </Modal.Body>
      <Modal.Footer className="bottom-0 flex justify-end space-x-4">
        <Button type="button" variant="secondary" onClick={onCloseWithConfirm}>
          {t("cancel")}
        </Button>
        <Button type="submit" variant="primary" disabled={!submitEnabled}>
          {isLoading ? t("saving_changes") : t("save_changes")}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};

Content.propTypes = {
  spaceId: PropTypes.number.isRequired,
  spaceIsPrivate: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
