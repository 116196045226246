import { delay, isFunction, throttle } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { PopoverWrapper as ReactionPopover } from "@circle-react/components/Reactions/popover/PopoverWrapper";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { usePunditUserContext } from "@circle-react/contexts";
import { useRailbar, useThreads } from "@circle-react/hooks/chatsV2";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import {
  getChatRoomMessageRedirectionUrl,
  scrollToMessage,
} from "../../helpers";

const DELAY_TIME = 5000; // 5 seconds

export interface HoverActionsProps {
  chatRoom: any;
  chatThreadsEnabled?: boolean;
  currentParticipant?: any;
  isMessageThread?: boolean;
  isParentMessage?: boolean;
  isRenderedOnHeader?: boolean;
  message: any;
  onBookmarkToggle?: (messageId: any) => void;
  setActiveMessageId: (messageId: string) => void;
}

export const HoverActions = ({
  message = {},
  chatRoom,
  isMessageThread = false,
  chatThreadsEnabled = false,
  isParentMessage = false,
  isRenderedOnHeader = false,
  currentParticipant,
  onBookmarkToggle,
  setActiveMessageId,
}: HoverActionsProps) => {
  const { id, embedded } = message;
  const { removeThreadIdUrlParam } = useThreads();
  const {
    openThreadsRailbar,
    setMessageHighlightId,
    isLiveStream,
    isCourseSpace,
    isDrawer,
  } = useRailbar();
  const isLiveOrCourseChat = isLiveStream || isCourseSpace;
  const isThreadsListingPage = window.location.href.includes("threads");
  const shouldDisplayBookmarkButton =
    isFunction(onBookmarkToggle) && !isLiveOrCourseChat;
  const shouldDisplayOpenButton =
    isParentMessage &&
    isMessageThread &&
    !isRenderedOnHeader &&
    !isLiveOrCourseChat &&
    !isDrawer;
  const history = useHistory();
  const { community_member_id: currentCommunityMemberId } =
    currentParticipant || {};
  const { currentCommunitySettings } = usePunditUserContext();
  const { chat_reactions_enabled: isChatReactionsEnabled } =
    currentCommunitySettings || {};

  const scrollToParentMessage = throttle(
    () => {
      setMessageHighlightId(id);
      scrollToMessage(id, () => {
        delay(() => setMessageHighlightId(null), DELAY_TIME);
      });
    },
    DELAY_TIME,
    { leading: true, trailing: false },
  );

  const redirectToMessage = () => {
    isThreadsListingPage || isRenderedOnHeader
      ? history.push(getChatRoomMessageRedirectionUrl(chatRoom, message))
      : scrollToParentMessage();
  };

  return (
    <>
      {isChatReactionsEnabled && currentCommunityMemberId && (
        <ReactionPopover
          reactions={message.reactions}
          currentCommunityMemberId={currentCommunityMemberId}
          reactionableId={message.id}
          reactionableType="chat_room_message"
          setActiveMessageId={setActiveMessageId}
          isMessageThread={isMessageThread}
        />
      )}
      {shouldDisplayBookmarkButton && (
        <BookmarkButton
          bookmarkType="message"
          record={message}
          onSuccess={() => onBookmarkToggle(message.id)}
        />
      )}
      {chatThreadsEnabled && !isMessageThread && (
        <TippyV2
          content={t("messaging.reply_in_threads")}
          dataTestId="reply-thread"
          interactive={false}
        >
          <IconButton
            name="16-open-threads"
            ariaLabel={t("messaging.reply_in_threads")}
            onClick={() => {
              removeThreadIdUrlParam();
              openThreadsRailbar(message, chatRoom);
            }}
            iconSize={16}
          />
        </TippyV2>
      )}
      {shouldDisplayOpenButton && (
        <TippyV2
          content={t(
            embedded ? "messaging.open_in_space" : "messaging.open_in_messages",
          )}
          interactive={false}
        >
          <IconButton
            name="16-open-in-space"
            ariaLabel={t(
              embedded
                ? "messaging.open_in_space"
                : "messaging.open_in_messages",
            )}
            onClick={redirectToMessage}
            iconSize={16}
          />
        </TippyV2>
      )}
    </>
  );
};
