import { useEffect, useState } from "react";
import type { RefObject } from "react";
import type { Editor } from "@tiptap/react";
import { defer } from "lodash";
import type { UseFormReturn } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { t } from "@/i18n-js/instance";
import { isValidTiptapBody } from "@circle-react/components/QuickPostV2/validation";
import { usePunditUserContext } from "@circle-react/contexts";
import { FormTipTapBlockEditor } from "@circle-react-shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import {
  mentionBlock,
  textBlock,
} from "@circle-react-shared/uikit/TipTap/utilities/contentBuilder";
import { Toolbar } from "@circle-react-shared/uikit/TipTapBlockEditor/Toolbar";
import { Button } from "@circle-react-uikit/Button";

const hasTriggerFormSubmit = (
  form: UseFormReturn,
): form is UseFormReturn & { triggerFormSubmit: () => void } =>
  "triggerFormSubmit" in form;

interface TiptapCommentBoxProps {
  spaceId: number;
  communityMemberForReply: any;
  autoFocus?: boolean;
  editorRef?: RefObject<Editor>;
  postId: number;
  parentCommentId?: number;
  isEdit?: boolean;
  initialSgidToObjectMap?: object;
}

/**
 * @deprecated
 * Please use the new TiptapCommentBox component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const TiptapCommentBox = ({
  spaceId,
  postId,
  communityMemberForReply,
  autoFocus,
  editorRef,
  parentCommentId,
  isEdit,
  initialSgidToObjectMap = {},
}: TiptapCommentBoxProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const [sgidToObjectMap, setSgidToObjectMap] = useState(
    initialSgidToObjectMap,
  );
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const formContext = useFormContext();
  const {
    watch,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = formContext;
  const tiptapBody = watch("tiptap_body");
  const isEditorEmpty = isValidTiptapBody(tiptapBody);
  const isSubmitDisabled = !isEditorEmpty || isSubmitting;
  const isSubmitShortcutEnabled = isEditorFocused && !isSubmitDisabled;

  useHotkeys(
    "cmd+enter, ctrl+enter",
    () => {
      if (hasTriggerFormSubmit(formContext)) {
        formContext.triggerFormSubmit();
      }
    },
    {
      enableOnContentEditable: true,
      enabled: isSubmitShortcutEnabled,
    },
    [formContext, isSubmitShortcutEnabled],
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setEditorKey(key => key + 1);
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    if (
      communityMemberForReply &&
      communityMemberForReply.id !== currentCommunityMember?.id
    ) {
      setSgidToObjectMap({
        ...sgidToObjectMap,
        [communityMemberForReply.rich_text_field_sgid]: communityMemberForReply,
      });
      defer(() => {
        editorRef?.current
          ?.chain?.()
          .focus("start")
          .insertContent([
            mentionBlock({
              sgid: communityMemberForReply.rich_text_field_sgid,
            }),
            textBlock({
              text: " ",
            }),
          ])
          .run();
      });
    }
  }, [communityMemberForReply, currentCommunityMember, editorRef]);

  useEffect(() => {
    defer(() => {
      if (autoFocus) {
        editorRef?.current?.commands?.focus?.("end");
      }
    });
  }, []);

  const toolbarPortalId = [
    "post",
    postId,
    isEdit ? "edit" : "create",
    parentCommentId,
    "comment-form-toolbar",
  ]
    .filter(Boolean)
    .join("-");
  return (
    <div
      key={editorKey}
      className="border-primary space-y-3 rounded-lg border px-4 py-3"
    >
      <FormTipTapBlockEditor
        editorRef={editorRef}
        placeholder={t("write_a_comment")}
        name="tiptap_body.body"
        onFocusChange={setIsEditorFocused}
        toolbars={[
          {
            portalId: toolbarPortalId,
            component: Toolbar,
          },
        ]}
        className="h-full max-h-[400px] overflow-y-auto"
        editorClassName="h-full"
        spaceId={spaceId}
        sgidToObjectMap={sgidToObjectMap}
        disabledExtensions={["poll", "ai_copilot", "cta"]}
      />
      <div className="flex items-center justify-between">
        <div id={toolbarPortalId} />
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitDisabled}
          small
          className="!px-4"
        >
          {isEdit
            ? isSubmitting
              ? t("comments.create.actions.saving")
              : t("comments.create.actions.save")
            : isSubmitting
              ? t("comments.create.actions.submitting")
              : t("comments.create.actions.submit")}
        </Button>
      </div>
    </div>
  );
};
