import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  ROOM_TYPES,
  ROOM_VIEW_TYPES,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { DROPDOWN_SIZE, Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";

export const ViewType = ({ name, roomType = ROOM_TYPES.STREAM }) => {
  const { control } = useFormContext();
  const viewTypeOptions = [
    {
      value: ROOM_VIEW_TYPES.GRID,
      label: t("live_streams.form.view_type_grid"),
      icon: "gallery-view",
      description: (
        <Typography.LabelSm>
          {t(`live_streams.form.${roomType}.view_type_description_grid`)}
        </Typography.LabelSm>
      ),
    },
    {
      value: ROOM_VIEW_TYPES.SPEAKER,
      label: t("live_streams.form.view_type_speaker"),
      icon: "speaker-view",
      description: (
        <Typography.LabelSm>
          {t(`live_streams.form.${roomType}.view_type_description_speaker`)}
        </Typography.LabelSm>
      ),
    },
  ];
  const getOption = value =>
    viewTypeOptions.find(option => option.value === value);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const currentOption = getOption(value);

        return (
          <Dropdown
            direction="bottom-start"
            linkType="button"
            button={
              <Dropdown.MenuButton className="form-control relative inline-block w-full text-left">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <Icon
                      type={currentOption?.icon}
                      className="ml-1 mr-2 flex"
                    />
                    <Typography.LabelMd>
                      {currentOption?.label}
                    </Typography.LabelMd>
                  </div>
                  <div className="flex content-end">
                    <Icon type="cheveron-down" className="ml-1 mr-2" />
                  </div>
                </div>
              </Dropdown.MenuButton>
            }
            buttonClassName="w-full"
            className="relative w-full"
            size={DROPDOWN_SIZE.EXTRA_LARGE}
            onChange={onChange}
            options={viewTypeOptions}
            labelWeight="semibold"
          />
        );
      }}
    />
  );
};

ViewType.propTypes = {
  name: PropTypes.string,
  roomType: PropTypes.string,
};
