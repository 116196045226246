import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";

export interface CommentsClosedBannerProps {
  className?: string;
}

/**
 * @deprecated
 * Please use the new CommentsClosedBanner component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentsClosedBanner = ({
  className,
}: CommentsClosedBannerProps) => (
  <div className={className}>
    <div className="bg-tertiary flex items-center justify-center gap-2 rounded-md p-3 text-center">
      <Icon size={16} type="lock-v2" className="text-dark" />
      <Typography.LabelSm>
        {t("post.comments_section.comments_closed")}
      </Typography.LabelSm>
    </div>
  </div>
);
