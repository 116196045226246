import classnames from "classnames";
import { Counter } from "@circle-react/components/Events/Counter";
import { ShowPageLink } from "@circle-react/components/PostsV3/PostViewListItem/ShowPageLink";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PostName = () => {
  const { post, canManagePost } = useCurrentPostHelpers();
  const { usedIn } = useCurrentPostContext();
  const isSmScreen = useSmScreenMediaQuery();
  const shouldRenderPostNameInMinimalPostModal =
    usedIn === "minimal-post-modal" && isSmScreen;

  if (!post.name || (usedIn === "minimal-post-modal" && !isSmScreen)) {
    return null;
  }

  if (shouldRenderPostNameInMinimalPostModal) {
    return <Typography.TitleMd>{post.name}</Typography.TitleMd>;
  }

  const { event_setting_attributes: eventData } = post || {};
  const { starts_at: startsAt, ends_at: endsAt } = eventData || {};

  const classNames = classnames("post__header", {
    "post-meta--hidden": post.hide_meta_info,
  });

  return (
    <div className={classNames}>
      {startsAt && endsAt && (
        <Counter startsAt={startsAt} endsAt={endsAt} status={post?.status} />
      )}
      <h1
        className={classnames(
          "post__title break-words text-2xl font-bold leading-7",
          {
            "with-admin-actions": canManagePost,
          },
        )}
      >
        <ShowPageLink
          post={post}
          className="!text-darkest focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          shouldOpenPostModal
        >
          {post.name}
        </ShowPageLink>
      </h1>
    </div>
  );
};
