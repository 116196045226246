import { usePunditUserContext } from "@circle-react/contexts";

export const useIsMessagingEnabled = () => {
  const { currentCommunityMember } = usePunditUserContext();
  return Boolean(currentCommunityMember?.messaging_enabled || false);
};

// Add audience filter check here in future
export const useIsCommunityBotMessagingEnabled = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { community_bot_enabled: isCommunityBotEnabled } =
    currentCommunitySettings || {};
  return Boolean(isCommunityBotEnabled);
};

export const useShouldShowNewMessagingButton = () => {
  const isMessagingEnabled = useIsMessagingEnabled();
  const isBotMessagingEnabled = useIsCommunityBotMessagingEnabled();

  return isMessagingEnabled || isBotMessagingEnabled;
};

export const useShouldShowMessagingRoutes = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const isBotMessagingEnabled = useIsCommunityBotMessagingEnabled();
  const isMessagingEnabled = currentCommunityMember?.messaging_enabled || false;
  const enableDMs =
    !isMessagingEnabled && currentCommunityMember?.policies?.can_direct_message;

  const showMessagesTab =
    enableDMs ||
    (currentCommunityMember?.messaging_enabled_by_admin && isMessagingEnabled);
  return {
    shouldShowMessagingRoutes: Boolean(
      isBotMessagingEnabled || showMessagesTab,
    ),
    showMessagesTab: showMessagesTab,
  };
};
