import { useState } from "react";
import { noop } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { mapParticipantsPerStatus } from "@/react/components/Chat/MessagingV2/RailBar/helpers";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { DIRECT_CHAT_ROOM } from "@/react/components/constants";
import { getRouteWithPrevLocation } from "@/react/helpers/urlHelpers";
import { useChatRoom } from "@/react/hooks/chatsV2";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { chatRoomPath, internalApi } from "@circle-react/helpers/urlHelpers";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { UserImageStatus } from "@circle-react-shared/UserImageStatus";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useToast } from "@circle-react-uikit/ToastV2";
import { shouldShowMessageOption, shouldShowRemoveOption } from "./helpers";

interface ContentProps {
  spaceMembers: any[];
  hasMoreSpaceMembers?: boolean;
  space: any;
  refetchSpaceMembers: () => void;
  fetchNextPage: () => void;
}

interface ProfileActionItem {
  value: string;
  label: string;
  onClick: (() => void) | (() => Promise<void>);
}

export const Content = ({
  spaceMembers,
  space,
  refetchSpaceMembers,
  hasMoreSpaceMembers = false,
  fetchNextPage,
}: ContentProps) => {
  const toastr = useToast();
  const history = useHistory();
  const { findOrCreateChatRoom } = useChatRoom();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<any>(null);
  const memberProfileDrawer = useProfileDrawer();
  const { currentCommunityMember, isViewOnlyMasquerading } =
    usePunditUserContext();
  const localeScope = "members_directory.community_members.actions.space";

  const { mutate: leaveSpaceMutation, isLoading: isLeaving } = useMutation(
    async () => {
      await reactQueryDelete(
        internalApi.spaceMembers.leaveSpace({
          spaceId: space.id,
          memberId: memberToDelete?.community_member_id,
        }),
      );
    },
    {
      onError: (error: Error) => {
        toastr.error(error.message);
      },

      onSuccess: () => {
        toastr.success(t(`${localeScope}.successfully_removed`));
        refetchSpaceMembers();
      },

      onSettled: () => {
        setIsConfirmationModalOpen(false);
      },
    },
  );

  const getProfileActionItems = (member: any) => {
    const handleDirectMessaging = async () => {
      const chatRoomUuid = await findOrCreateChatRoom({
        kind: DIRECT_CHAT_ROOM,
        community_member_ids: [member?.community_member_id],
      });
      history.push(
        getRouteWithPrevLocation({ url: chatRoomPath({ uuid: chatRoomUuid }) }),
      );
    };

    const profileActionItems: ProfileActionItem[] = [
      {
        value: "profile",
        label: t("view_profile"),
        onClick: () =>
          memberProfileDrawer.show({ memberPublicId: member?.user_public_uid }),
      },
    ];

    if (shouldShowMessageOption(currentCommunityMember, member)) {
      profileActionItems.push({
        value: "message",
        label: t("message"),
        onClick: isViewOnlyMasquerading ? noop : handleDirectMessaging,
      });
    }
    if (shouldShowRemoveOption(currentCommunityMember, member)) {
      profileActionItems.push({
        value: "remove",
        label: t("remove"),
        onClick: () => {
          setMemberToDelete(member);
          setIsConfirmationModalOpen(true);
        },
      });
    }

    return profileActionItems;
  };

  const { onlineParticipants, offlineParticipants } =
    mapParticipantsPerStatus(spaceMembers);

  const renderParticipantList = (participantList: any) =>
    participantList.map((member: any) => (
      <button
        type="button"
        className="hover:bg-tertiary chat-space-rail__content--member flex w-full cursor-pointer justify-between"
        key={member?.id}
        onClick={() =>
          memberProfileDrawer.show({ memberPublicId: member?.user_public_uid })
        }
      >
        <div className="text-dark flex w-[90%] items-center text-left">
          <div className="mr-3">
            <UserImageStatus
              name={member?.name}
              src={member?.avatar_url}
              status={member?.status}
              size="9"
            />
          </div>
          <div>{member?.name}</div>
          {member?.community_admin ? (
            <BadgeV2 square className="ml-2 uppercase" label={t("admin")} />
          ) : (
            member?.moderator && (
              <BadgeV2
                square
                className="ml-2 uppercase"
                label={t("moderator")}
              />
            )
          )}
        </div>
        <button
          type="button"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {!isViewOnlyMasquerading && (
            <Dropdown
              direction="bottom-start"
              menuButtonEl="div"
              button={
                <Dropdown.MenuButton id="action-block">
                  <IconButton
                    name="20-menu-dots-horizontal"
                    iconSize={20}
                    ariaLabel={t("chat_space.view_member_actions")}
                  />
                </Dropdown.MenuButton>
              }
              options={getProfileActionItems(member)}
            />
          )}
        </button>
      </button>
    ));

  return (
    <>
      <div
        id="scrollableDiv"
        className="chat-space-rail__content p-0 md:py-4"
        style={{ height: "100%", overflow: "auto" }}
      >
        <InfiniteScroll
          scrollThreshold={0.8}
          next={fetchNextPage}
          hasMore={hasMoreSpaceMembers}
          dataLength={spaceMembers.length}
          className="p-0 px-6 pb-12"
          scrollableTarget="scrollableDiv"
          loader={
            <div className="flex justify-center">
              <Typography.LabelXxs weight="semibold">
                {t("toggle_with_loader.loading")}
              </Typography.LabelXxs>
            </div>
          }
        >
          {onlineParticipants.length > 0 && (
            <>
              <div className="text-default mb-2">
                <Typography.LabelXxs weight="semibold">
                  {t("chat_space.online_now")}
                </Typography.LabelXxs>
              </div>
              {renderParticipantList(onlineParticipants)}
            </>
          )}
          {offlineParticipants.length > 0 && (
            <>
              <div className="text-default my-2">
                <Typography.LabelXxs weight="semibold">
                  {t("chat_space.offline")}
                </Typography.LabelXxs>
              </div>
              {renderParticipantList(offlineParticipants)}
            </>
          )}
        </InfiniteScroll>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={t("remove")}
        onClose={() => setIsConfirmationModalOpen(false)}
        onCancel={() => setIsConfirmationModalOpen(false)}
        onConfirm={leaveSpaceMutation}
        confirmText={isLeaving ? t("removing") : t("remove")}
        disabled={isLeaving}
        confirmVariant="danger"
      >
        {t(`${localeScope}.confirm_remove`, {
          community_member: memberToDelete?.name,
          space: space.name,
        })}
      </ConfirmationModal>
    </>
  );
};
