import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { useCurrentPostContext } from "@/react/contexts";
import { fetchRequest } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/Modal";
import { useToast } from "@circle-react-uikit/ToastV2";

const REPORT_REASONS = [
  "harassment",
  "spam",
  "incorrect_location",
  "against_guidelines",
  "other",
];

/**
 * @deprecated
 * Please use the new ReportPostModal component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const ReportPostModal = ({ onClose, show, post }) => {
  const { usedIn } = useCurrentPostContext();

  const toast = useToast();

  const reportPost = data =>
    fetchRequest(internalApi.flaggedContent.create(), {
      method: "POST",
      forReactQuery: true,
      body: JSON.stringify({ flagged_content: data }),
    });

  const handleReport = useMutation(reportPost, {
    onSuccess: () => {
      toast.success(t("report_post_success"));
      onClose();
    },
    onError: () => {
      toast.error(t("something_went_wrong"));
    },
  });

  const reportReasonOptions = REPORT_REASONS.map(reason => ({
    value: reason,
    label: t(`flagged_contents.report_reasons.${reason}`),
  }));

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("flagged_contents.form.report_post")}
      containerClassName={usedIn === "full-post-modal" && "!z-[1061]"}
    >
      <Form
        defaultValues={{
          flagged_contentable_id: post.id,
          flagged_contentable_type: "Post",
        }}
        onSubmit={handleReport.mutate}
      >
        <Form.Item
          name="reported_reason_type"
          label={t("flagged_contents.form.reported_reason_type")}
          description={t(
            "flagged_contents.form.reported_reason_type_description",
          )}
          hideBorder
        >
          <Form.SelectNative options={reportReasonOptions} />
        </Form.Item>
        <Form.Item
          name="reported_reason_body"
          label={t("flagged_contents.form.reported_reason_body")}
          description={t(
            "flagged_contents.form.reported_reason_body_description",
          )}
          hideBorder
          fullWidth
        >
          <Form.TextArea />
        </Form.Item>
        <Button
          type="submit"
          variant="primary"
          data-testid="report_post_submit"
          formButton
        >
          {I18n.t("report")}
        </Button>
      </Form>
    </Modal>
  );
};
