import { useState } from "react";
import { t } from "@/i18n-js/instance";
import {
  useCurrentUser,
  useIsOrCanBeCommunityMember,
} from "@circle-react/contexts";
import { newSessionPath } from "@circle-react/helpers/urlHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";

/**
 * @deprecated
 * Please use the new useReplyHandler hook from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const useReplyHandler = ({ comment, showReplyForm, replyFormRef }) => {
  const { is_replying_enabled: isReplyingEnabled } = comment;
  const currentUser = useCurrentUser();
  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();
  const isIframeEmbed = useIsIframeEmbed();
  const [shouldShowReplyForm, setShouldShowReplyForm] = useState(showReplyForm);
  const [communityMemberForReply, setCommunityMemberForReply] = useState(null);

  const openReplyForm = ({ communityMember }) => {
    if (!currentUser) {
      const loginPath = newSessionPath({
        params: {
          notice: t("comments.log_in_to_add_a_comment"),
          post_login_redirect: window.location.href,
        },
      });

      if (isIframeEmbed) {
        window.open(loginPath, "_blank", "noreferrer");
      } else {
        window.location.href = loginPath;
      }

      return;
    }

    setCommunityMemberForReply(communityMember);
    setShouldShowReplyForm(true);

    // Focus the reply box if its already open
    if (replyFormRef.current) {
      replyFormRef.current.commands?.focus?.("end");
    }
  };

  return {
    openReplyForm,
    shouldShowReplyForm,
    communityMemberForReply,
    canReply: isReplyingEnabled && isOrCanBeCommunityMember,
  };
};
